import * as React from 'react'
import { useState, useEffect, useCallback } from "react";
import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getCandidateById } from '../../lib/db_candidate';
import { TabContext, TabPanel } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import BlacklistCandidate from './BlacklistCandidate';
import { yyyyMMtoMonthYear } from '../../utils/eventDateTime';
import CandidateAttendedEvent from './CandidateAttendedEvent';
import CandidateAttachments from './CandidateAttachments';

function ViewCandidate({ isDialogOpen, handleCloseDialog, id }) {
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
    const [candidate, setCandidate] = useState({})
    const [tabIndex, setTabIndex] = useState("1");
    const [display1, setDisplay1] = useState([])
    const [display2, setDisplay2] = useState([])
    const [language1, setLanguage1] = useState([])
    const [language2, setLanguage2] = useState([])
    const [workDisplay, setWorkDisplay] = useState([])
    const [color, setColor] = useState({
        'bg': "#067E41",
        'line': '#067E41',
        'font': '#FFFFFF'
    })
    const [isLoading,setIsLoading] = useState(false)

    const getCandidate = useCallback(async () => {
        if (id === "") {
            return
        }
        setIsLoading(true)
        const res = await getCandidateById(id)
        res.exception_status = res.exception_status ?? "N/A"
        res.id = id
        switch (res.exception_status) {
            case "N/A":
                setColor({
                    'font': '#FFFFFF',
                    'line': '#067E41',
                    'bg': '#067E41'
                })
                break;
            case "Under Age":
                setColor({
                    'bg': "#DC3224",
                    'line': '#DC3224',
                    'font': '#FFFFFF'
                })
                break;
            case "KIV":
                setColor({
                    'bg': "#D97F00",
                    'line': '#D97F00',
                    'font': '#FFFFFF'
                })
                break;
            case "Blacklisted":
                setColor({
                    'bg': "#DC3224",
                    'line': '#DC3224',
                    'font': '#FFFFFF',
                })
                break;
            case "Under Cooling Period":
                setColor({
                    'bg': "#DC3224",
                    'line': '#DC3224',
                    'font': '#FFFFFF'
                })
                break;
            default:
                setColor({
                    'bg': "#067E41",
                    'line': '#067E41',
                    'font': '#FFFFFF'
                })
                break;
        }
        const objectCandidate1 = {
            "Workday Candidate ID": res.workday_candidate_id,
            "First Name": res.first_name,
            "Last Name": res.last_name,
            "Nationality": res.nationality,
            "Country": res.country,
            "Email": res.email,
            "LinkedIn Profile": res.linkin_url_profile,
            "Have you worked at Airasia before?": res.have_you_worked_at_airasia_before
        }
        const objectCandidate2 = {
            "Gender": res.gender,
            "Date Of Birth": res.dob,
            "Age": res.age,
            "City": res.city,
            "Phone Number": res.phone_number,
            "National ID/ Gov Issued ID/ Passport": res.national_id,
            "How do you hear about this job?": res.how_you_heard_about_this_job,
        }
        const disp1 = Object.entries(objectCandidate1).map(([key, value], index) => (
            <div key={`dsip1-item-${index}`} style={{ marginBottom: '16px' }}>
                <strong>{key}</strong>
                <Typography color="#4C4C50" style={{ width: '300px', wordWrap: "break-word" }}>{value}</Typography>
            </div>
        ))
        const disp2 = Object.entries(objectCandidate2).map(([key, value], index) => (
            <div key={`disp2-item-${index}`} style={{ marginBottom: '16px' }}>
                <strong>{key}</strong>
                <Typography color="#4C4C50" style={{ wordWrap: "break-word" }}>{value}</Typography>
            </div>
        ))

        disp2.unshift(
            <>
                <div key={`exception-status`} style={{ marginBottom: '16px' }}>
                    <strong>Exception Status</strong>
                    <Box display={"flex"} mt={1} gap={2}>
                        <Typography color={color.font} style={{ padding: '4px 16px', alignItems: 'center', minWidth: "160px", backgroundColor: color.bg, border: 1, borderColor: color.line, borderRadius: '50px' }}>
                            {res.exception_status}
                        </Typography>
                        
                        {(res.exception_status === "Blacklisted" || res.exception_status === "Under Cooling Period") && <Button
                            onClick={() => setIsEditDialogOpen(!isEditDialogOpen)}
                            sx={{
                                border: 1,
                                borderColor: "#ebebeb", top: "0px", right: "-8px",
                                p: "0", lineHeight: "32px", height: "32px", minWidth: "32px"
                            }}>
                            <EditIcon fontSize="small" sx={{ color: "#777" }} />
                        </Button>}
                    </Box>
                </div>
                { res.exception_status === "Under Cooling Period" && (
                    <div key={`cooling-period-expiry`} style={{ marginBottom: '16px' }}>
                        <strong>Cooling Period Expiry</strong>
                        <Typography>{res.cooling_period_expiry ?? ""}</Typography>
                    </div>
                )}
            </>
        )

        const lang1 = []
        const lang2 = []

        for (let index = 0; index < res.language_spoken.length; index++) {
            if (lang1.length < 6) {
                lang1.push(
                    <div key={`lang1-item-${index}`} style={{ marginBottom: '16px' }}>
                        <strong>{res.language_spoken[index].language} - {res.language_spoken[index].language_ability_type}</strong>
                        <Typography color="#4C4C50" style={{ wordWrap: "break-word" }}>{res.language_spoken[index].language_proficiency}</Typography>
                    </div>
                )
            } else {
                lang2.push(
                    <div key={`lang2-item-${index}`} style={{ marginBottom: '16px' }}>
                        <strong>{res.language_spoken[index].language} - {res.language_spoken[index].language_ability_type}</strong>
                        <Typography color="#4C4C50" style={{ wordWrap: "break-word" }}>{res.language_spoken[index].language_proficiency}</Typography>
                    </div>
                )
            }
        }

        const work = []

        for (let index = 0; index < res.work_experience.length; index++) {
            work.push(
                <div key={`work-item-${index}`} tyle={{ marginBottom: '16px' }}>
                    <Typography mb="2px" color="#D97F00" fontWeight="bold">{res.work_experience[index].job_title}</Typography>
                    <Typography mb="2px" color="#000" fontWeight="bold">{res.work_experience[index].company_name}</Typography>
                    <Box display="flex" flexDirection="row" width="100%">
                        <Box sx={{ flex: 1 }}>
                            <Typography color="#4B4B4B">{yyyyMMtoMonthYear(res.work_experience[index].from)}{res.work_experience[index].to ? " - " : ""}{yyyyMMtoMonthYear(res.work_experience[index].to)}</Typography>
                            {res.work_experience[index]?.role_description && (
                               <ul style={{ margin: 0, paddingLeft: 24 }}>
                                    <li>{res.work_experience[index].role_description}</li>
                                </ul> 
                            )}
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography color="#4B4B4B">{res.work_experience[index].location}</Typography>
                        </Box>
                    </Box>
                </div>
            )
        }

        setDisplay1(disp1)
        setDisplay2(disp2)
        setLanguage1(lang1)
        setLanguage2(lang2)
        setWorkDisplay(work)
        setCandidate(res)
        setIsLoading(false)
    }, [id, color.bg, color.font, color.line, isEditDialogOpen])
    useEffect(() => {
        if (id !== undefined) {
            setDisplay1([])
            setDisplay2([])
            setLanguage1([])
            setLanguage2([])
            setWorkDisplay([])
            setCandidate({})
            getCandidate()
        }
    }, [id, getCandidate])

    const handleChangeEditTabs = (e, activeTab) => {
        setTabIndex(activeTab)
    }

    function handleClose() {
        handleCloseDialog(false);
        setTabIndex("1")
    }
    return (
        <div>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"xl"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ minHeight: '100vh' }}
            >
                <DialogTitle sx={{ backgroundColor: "#4B4FA6" }}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                        <Typography color='#FFFFFF' variant='h6'>Workday Candidate ID: {candidate.workday_candidate_id}</Typography>
                        <IconButton id='close' name='close' onClick={handleClose}>
                            <CloseIcon id='close' name='close' sx={{
                                pointerEvents: 'none',
                                color: 'white'
                            }} />
                        </IconButton>
                    </Box>
                    <Typography color='#FFFFFF'>{candidate.first_name} {candidate.last_name}</Typography>
                </DialogTitle>
                <TabContext value={tabIndex}>
                    <Box p={1} sx={{ border: 1, borderColor: "#DEDEDE", borderWidth: "1px" }}>
                        <Tabs value={tabIndex} onChange={handleChangeEditTabs}>
                            <Tab disableRipple label={"DETAILS"} value="1" />
                            <Tab disableRipple label={"EVENTS APPLIED/ATTENDED"} value="2" />
                            <Tab disableRipple label={"ATTACHMENTS"} value="3" />
                        </Tabs>
                    </Box>
                    <DialogContent>
                    { !isLoading ?<>
                        <TabPanel value='1' sx={{ border: 1, borderColor: "#EBEBEB", m: 1 }}>
                            <Typography variant='h6' style={{ marginBottom: "18px", marginTop: "-20px" }}>Personal Information</Typography>
                            <Box display="flex" flexDirection="row" width="100%" >
                                <Box sx={{ flex: 1 }}>
                                    {display1}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    {display2}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    {candidate.candidate_status === "Failed" && <div style={{ marginBottom: '16px' }}>
                                        <strong>Cooling Period Due Date</strong>
                                        <Typography color="#4C4C50" style={{ wordWrap: "break-word" }}>{candidate.cooling_period_expiry}</Typography>
                                    </div>}
                                </Box>
                            </Box>
                            <Typography variant='h6' style={{ marginBottom: "18px" }}>Languages Spoken</Typography>
                            <Box display="flex" flexDirection="row" width="100%">
                                <Box sx={{ flex: 1 }}>
                                    {language1}
                                </Box>
                                <Box sx={{ flex: 1 }}>
                                    {language2}
                                </Box>
                            </Box>
                            { workDisplay.length > 0 && (
                                <>
                                    <Typography variant='h6' style={{ marginBottom: "18px" }}>Working Experience</Typography>
                                    {workDisplay}
                                </>
                            )}
                        </TabPanel>
                        <TabPanel value='2'>
                            <CandidateAttendedEvent candidateId={id} candidateName={candidate.first_name+" "+candidate.last_name}/>
                        </TabPanel>
                        <TabPanel value='3'>
                            <CandidateAttachments candidateId={id} />
                        </TabPanel>
                        </>: <CircularProgress/>}
                    </DialogContent>
                </TabContext>
            </Dialog>
            <BlacklistCandidate isDialogOpen={isEditDialogOpen} handleCloseDialog={() => setIsEditDialogOpen(false)} candidate={candidate} isUplift={true} />
        </div>
    )
}

export default ViewCandidate