import { storage } from "./firebase.js";

import {
    ref,
    uploadBytesResumable,
    getDownloadURL
} from "firebase/storage"

import {
    getEventById,
    // getEventByJrNumber,
    getEventAssessmentResult,
    getEventCandidateIds,
    addCandidateToEvent,
    getAllCandidateNumberFromEvent,
    // getCandidateNumberFromEvent,
    assignCandidateNumberToEventCandidate,
    getEventEmailTemplateFinalStatus,
    setEmailSent,
    getEmailSentList,
    getEventEmailTemplateById,
    getAllJrNumberDetails,
    getJrNumberDetailsByJr,
} from './db_event.js';

import {
    registerWalkInCandidate,
    getCandidateWalkInRegistrationStatus,
    getCandidateById,
    getAllCandidate,
    addEventToCandidate,
    updateCandidateBlacklistStatus,
    updateCandidateCoolingPeriod,
    updateCandidateRegistrationStatus,
    getCandidateAssessmentResultDocumentCheck,
    setCandidateAssessmentResultDocumentCheck,
    getCandidateAssessmentResultMeasurement,
    setCandidateAssessmentResultMeasurement,
    getCandidateAssessmentResultCatwalk,
    setCandidateAssessmentResultCatwalk,
    getCandidateAssessmentResultOneToOneInterview,
    setCandidateAssessmentResultOneToOneInterview,
    getCandidateAssessmentResultEnglishExam,
    setCandidateAssessmentResultEnglishExam,
    getCandidateAssessmentResultGroupDynamic,
    setCandidateAssessmentResultGroupDynamic,
    getCandidateAssessmentResultComment,
    setCandidateAssessmentResultComment,
    getAllCandidateJobApplication,
    // getCandidateJobApplicationById,
    addCandidateJobApplication,
    addCandidateBlacklistHistory,
    addCandidateCoolingPeriodHistory,
    checkIfCandidateExists,
    getCompletedEventRemarks,
    checkIfNationalIdExists,
    updateRegistrationData
} from './db_candidate.js';

import {
    generateSendEmailToken,
    generateAcceptInvitationToken
} from "./db_token"

import {
    sendEmail
} from "./cloud_function"
import { getAge, isCoolingPeriod } from "../utils/isDate.js";
import getTime from "../utils/getTime.js";
import { sortCandidateByCandidateNumber } from "../utils/sortCandidate.js";
import arrayToString from "../utils/arrayToString.js";
import apiService from "./apiService.js";
import { decryptData } from "../utils/encryption.js";


const getDashboardDetails = async (eventId, eventInfo, candidates) => {
    const dashboard = {};

    dashboard['total_registered'] = 0;
    dashboard['total_pending_registration'] = 0;
    dashboard['total_exception'] = 0;

    dashboard['total_in_progress'] = 0;
    dashboard['total_pass'] = 0;
    dashboard['total_failed'] = 0;
    dashboard['total_kiv'] = 0;


    dashboard['measurement'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    dashboard['document_check'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    dashboard['catwalk'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    dashboard['english_exam'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    dashboard['group_dynamic'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    dashboard['one_to_one_interview'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };

    console.log(dashboard);

    const assessmentResult = await getEventAssessmentResult(eventId)
    //console.log(assessmentResult)
    const mapAssessmentResult = {}
    for (const candidateId in assessmentResult) {
        //console.log(assessmentResult[candidateId])
        // Assigned default value for candidate assessment result
        mapAssessmentResult[candidateId] = 'Pending'
        let resultCount = {
            passed: 0,
            failed: 0,
            kiv: 0
        }

        for (const moduleName in assessmentResult[candidateId].result) {
            //console.log(moduleName)
            //console.log(assessmentResult[candidateId].result[moduleName])
            if (assessmentResult[candidateId]["result"][moduleName] === "Passed") {
                dashboard[moduleName].passed++
                resultCount.passed++
            }
            else if (assessmentResult[candidateId]["result"][moduleName] === "Failed") {
                dashboard[moduleName].failed++
                resultCount.failed++
            }
            else if (assessmentResult[candidateId]["result"][moduleName] === "KIV") {
                resultCount.kiv++
            }
        }

        if (resultCount.passed === eventInfo.total_assessment ) {
            // All module passed
            dashboard['total_pass']++
            mapAssessmentResult[candidateId] = 'Passed'
        }
        else if (resultCount.failed > 0) {
            // One module failed => interview failed
            dashboard['total_failed']++
            mapAssessmentResult[candidateId] = 'Failed'
        }
        else if (resultCount.passed > 0 && resultCount.failed === 0 && resultCount.kiv === 0) {
            dashboard['total_in_progress']++
            mapAssessmentResult[candidateId] = 'In Progress'

            // Process Assessment Module In Progress
            //console.log(assessmentResult[candidateId])
            if (!("measurement" in assessmentResult[candidateId].result)) {
                dashboard.measurement.remaining++
            }

            if (!("document_check" in assessmentResult[candidateId].result)) {
                dashboard.document_check.remaining++
            }

            if (!("catwalk" in assessmentResult[candidateId].result)) {
                dashboard.catwalk.remaining++
            }

            if (!("english_exam" in assessmentResult[candidateId].result)) {
                dashboard.english_exam.remaining++
            }

            if (!("group_dynamic" in assessmentResult[candidateId].result)) {
                dashboard.group_dynamic.remaining++
            }

            if (!("one_to_one_interview" in assessmentResult[candidateId].result)) {
                dashboard.one_to_one_interview.remaining++
            }
        }
        else if (resultCount.kiv > 0) {
            // One or more module is KIV, no failed => KIV
            dashboard['total_kiv']++
            mapAssessmentResult[candidateId] = 'KIV'
        }
    }

    for ( const candidate of candidates ) {
        if ( candidate.registration_status === "Pending" ) {
            dashboard['total_pending_registration']++
        } else if ( candidate.registration_status === "Confirmed" ) {
            dashboard['total_registered']++
        } else {
            dashboard['total_exception']++
        }
    }

    dashboard['total_invited'] = candidates.length;
    
    return dashboard;
}

const getEventDetailsPageInfoByEvenId = async (eventId) => {
    const eventDetailsPageInfo = {
        eventInfo: {},
        dashboard: {},
        candidateList: []
    };
    const eventInfo = await getEventById(eventId);
    //console.log(eventInfo);
    eventDetailsPageInfo.eventInfo['event_id'] = eventInfo['event_code'];
    eventDetailsPageInfo.eventInfo['event_name'] = eventInfo['event_name'];
    eventDetailsPageInfo.eventInfo['jr_number'] = eventInfo['jr_number'];
    eventDetailsPageInfo.eventInfo['company_name'] = eventInfo['event_aoc'];
    eventDetailsPageInfo.eventInfo['event_location'] = eventInfo['event_venue'];
    eventDetailsPageInfo.eventInfo['event_date_time'] = eventInfo['event_date_time'];
    eventDetailsPageInfo.eventInfo['event_status'] = eventInfo['event_status'];
    let dateFormat = new Date();
    dateFormat.setTime(eventId);
    eventDetailsPageInfo.eventInfo['event_creation_date'] = dateFormat.getDate() +
        "/" + (dateFormat.getMonth() + 1) +
        "/" + dateFormat.getFullYear() +
        " " + dateFormat.getHours() +
        ":" + dateFormat.getMinutes() +
        ":" + dateFormat.getSeconds();
    eventDetailsPageInfo.eventInfo['total_assessment'] = eventInfo['event_assessment_module'].length;
    eventDetailsPageInfo.dashboard['total_registered'] = 0;
    eventDetailsPageInfo.dashboard['total_pending_registration'] = 0;
    eventDetailsPageInfo.dashboard['total_exception'] = 0;

    eventDetailsPageInfo.dashboard['total_in_progress'] = 0;
    eventDetailsPageInfo.dashboard['total_pass'] = 0;
    eventDetailsPageInfo.dashboard['total_failed'] = 0;
    eventDetailsPageInfo.dashboard['total_kiv'] = 0;

    eventDetailsPageInfo.dashboard['measurement'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    eventDetailsPageInfo.dashboard['document_check'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    eventDetailsPageInfo.dashboard['catwalk'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    eventDetailsPageInfo.dashboard['english_exam'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    eventDetailsPageInfo.dashboard['group_dynamic'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };
    eventDetailsPageInfo.dashboard['one_to_one_interview'] = {
        remaining: 0,
        failed: 0,
        passed: 0
    };

    const eventCandidateIds = await getEventCandidateIds(eventId)

    const allCandidates = await getAllCandidate()
    const mapAllCandidates = {}
    for (const obj of allCandidates) {
        mapAllCandidates[obj.id] = { ...obj }
    }

    const candidateNumberList = await getAllCandidateNumberFromEvent(eventId)
    const mapcandidateNumberList = {}
    for (const obj of candidateNumberList) {
        mapcandidateNumberList[obj.candidate_id] = obj.candidate_number
    }
    //console.log(eventCandidateIds)
    //console.log(allCandidates)

    // Process assessment result
    const assessmentResult = await getEventAssessmentResult(eventId)
    //console.log(assessmentResult)
    const mapAssessmentResult = {}
    for (const candidateId in assessmentResult) {
        //console.log(assessmentResult[candidateId])
        // Assigned default value for candidate assessment result
        mapAssessmentResult[candidateId] = 'Pending'
        let resultCount = {
            passed: 0,
            failed: 0,
            kiv: 0
        }

        for (const moduleName in assessmentResult[candidateId].result) {
            //console.log(moduleName)
            //console.log(assessmentResult[candidateId].result[moduleName])
            if (assessmentResult[candidateId]["result"][moduleName] === "Passed") {
                eventDetailsPageInfo.dashboard[moduleName].passed++
                resultCount.passed++
            }
            else if (assessmentResult[candidateId]["result"][moduleName] === "Failed") {
                eventDetailsPageInfo.dashboard[moduleName].failed++
                resultCount.failed++
            }
            else if (assessmentResult[candidateId]["result"][moduleName] === "KIV") {
                resultCount.kiv++
            }
        }

        if (resultCount.passed === eventDetailsPageInfo.eventInfo['total_assessment']) {
            // All module passed
            eventDetailsPageInfo.dashboard['total_pass']++
            mapAssessmentResult[candidateId] = 'Passed'
        }
        else if (resultCount.failed > 0) {
            // One module failed => interview failed
            eventDetailsPageInfo.dashboard['total_failed']++
            mapAssessmentResult[candidateId] = 'Failed'
        }
        else if (resultCount.passed > 0 && resultCount.failed === 0 && resultCount.kiv === 0) {
            eventDetailsPageInfo.dashboard['total_in_progress']++
            mapAssessmentResult[candidateId] = 'In Progress'

            // Process Assessment Module In Progress
            //console.log(assessmentResult[candidateId])
            if (!("measurement" in assessmentResult[candidateId].result)) {
                eventDetailsPageInfo.dashboard.measurement.remaining++
            }

            if (!("document_check" in assessmentResult[candidateId].result)) {
                eventDetailsPageInfo.dashboard.document_check.remaining++
            }

            if (!("catwalk" in assessmentResult[candidateId].result)) {
                eventDetailsPageInfo.dashboard.catwalk.remaining++
            }

            if (!("english_exam" in assessmentResult[candidateId].result)) {
                eventDetailsPageInfo.dashboard.english_exam.remaining++
            }

            if (!("group_dynamic" in assessmentResult[candidateId].result)) {
                eventDetailsPageInfo.dashboard.group_dynamic.remaining++
            }

            if (!("one_to_one_interview" in assessmentResult[candidateId].result)) {
                eventDetailsPageInfo.dashboard.one_to_one_interview.remaining++
            }
        }
        else if (resultCount.kiv > 0) {
            // One or more module is KIV, no failed => KIV
            eventDetailsPageInfo.dashboard['total_kiv']++
            mapAssessmentResult[candidateId] = 'KIV'
        }
    }

    const candidateListInfoPromises = eventCandidateIds.map(async(candidateId) => {
        const candidateListInfo = mapAllCandidates[candidateId]
        
        candidateListInfo.id = candidateId
        candidateListInfo.full_name = mapAllCandidates[candidateId].first_name + " " + mapAllCandidates[candidateId].last_name
        
        if (eventInfo['event_status'] === "Completed") {
            candidateListInfo.assessment_status = await getCompletedEventRemarks(candidateId, eventId);
        } else {
            candidateListInfo.assessment_status = mapAssessmentResult[candidateId];
        }
        candidateListInfo.source = mapAllCandidates[candidateId].source
        if (mapAllCandidates[candidateId].hasOwnProperty('registration_status')) {
            candidateListInfo.registration_status = mapAllCandidates[candidateId].registration_status
        }
        else {
            // Generate default registration_status field if not exist
            candidateListInfo.registration_status = "Pending"
            await updateCandidateRegistrationStatus(candidateId, 'Pending')
        }

        candidateListInfo.exception_status = "N/A"

        // Exception status only valid fo 'Walk In' candidate.
        if (candidateListInfo.source === "Walk In") {
            if (mapAllCandidates[candidateId].is_blacklist === true) {
                candidateListInfo.exception_status = "Blacklisted"
                candidateListInfo.overall_register_status = "Exception - Blacklisted"
            }

            if (mapAllCandidates[candidateId].cooling_period_expiry) {
                const isException = isCoolingPeriod(mapAllCandidates[candidateId].cooling_period_expiry)
                if (isException === true) {
                    candidateListInfo.exception_status = "Under Cooling Period"
                    candidateListInfo.overall_register_status = "Exception - Cooling Period"
                }
            }
            
            if (mapAllCandidates[candidateId]?.is_kiv === true) {
                candidateListInfo.exception_status = "KIV";
                candidateListInfo.overall_register_status = "Exception - KIV"
            }
            
            if (mapAllCandidates[candidateId].dob) {
                const age = getAge(mapAllCandidates[candidateId].dob)
                if (age < 18) {
                    candidateListInfo.exception_status = "Under Age"
                    candidateListInfo.overall_register_status = "Exception - Under Age"
                }
            }
        }

        if (candidateListInfo.exception_status === "N/A") {
            candidateListInfo.overall_register_status = candidateListInfo.registration_status
        }
        else {
            eventDetailsPageInfo.dashboard['total_exception']++
        }

        candidateListInfo['candidate_number'] = mapcandidateNumberList[candidateId];

        eventDetailsPageInfo.candidateList.push(candidateListInfo)
        //console.log(candidateListInfo)

        switch (candidateListInfo.registration_status) {
            case "Pending":
                eventDetailsPageInfo.dashboard['total_pending_registration']++
                break
            case "Confirmed":
                eventDetailsPageInfo.dashboard['total_registered']++
                break
            default:
                //eventDetailsPageInfo.dashboard['total_exception']++
                break
            }
    })

    await Promise.all(candidateListInfoPromises)

    eventDetailsPageInfo.dashboard['total_invited'] = eventDetailsPageInfo.candidateList.length;
    eventDetailsPageInfo.candidateList = sortCandidateByCandidateNumber(eventDetailsPageInfo.candidateList)

    //console.log(eventDetailsPageInfo.dashboard)
    //console.log(eventDetailsPageInfo);
    
    return eventDetailsPageInfo;
}

const getCandidateEventAssessmentDocumentCheck = async (candidateId, eventId) => {
    return getCandidateAssessmentResultDocumentCheck(candidateId, eventId);
}

const setCandidateEventAssessmentDocumentCheck = async (candidateId, eventId, documentCheckResult) => {
    setCandidateAssessmentResultDocumentCheck(candidateId, eventId, documentCheckResult);
}

const getCandidateEventAssessmentMeasurement = async (candidateId, eventId) => {
    return getCandidateAssessmentResultMeasurement(candidateId, eventId);
}

const setCandidateEventAssessmentMeasurement = async (candidateId, eventId, measurementResult) => {
    setCandidateAssessmentResultMeasurement(candidateId, eventId, measurementResult);
}

const getCandidateEventAssessmentCatwalk = async (candidateId, eventId) => {
    return getCandidateAssessmentResultCatwalk(candidateId, eventId);
}

const setCandidateEventAssessmentCatwalk = async (candidateId, eventId, catwalkResult) => {
    setCandidateAssessmentResultCatwalk(candidateId, eventId, catwalkResult);
}

const getCandidateEventAssessmentOneToOneInterview = async (candidateId, eventId) => {
    return getCandidateAssessmentResultOneToOneInterview(candidateId, eventId);
}

const setCandidateEventAssessmentOneToOneInterview = async (candidateId, eventId, oneToOneInterviewResult) => {
    setCandidateAssessmentResultOneToOneInterview(candidateId, eventId, oneToOneInterviewResult);
}

const getCandidateEventAssessmentComment = async (candidateId, eventId) => {
    return getCandidateAssessmentResultComment(candidateId, eventId);
}

const setCandidateEventAssessmentComment = async (candidateId, eventId, assessmentComment) => {
    await setCandidateAssessmentResultComment(candidateId, eventId, assessmentComment);
}

const getCandidateEventAssessmentEnglishExam = async (candidateId, eventId) => {
    return getCandidateAssessmentResultEnglishExam(candidateId, eventId);
}

const getCandidateEventAssessmentGroupDynamic = async (candidateId, eventId) => {
    return getCandidateAssessmentResultGroupDynamic(candidateId, eventId);
}

const autoCreateCandidateJobApplication = async (eventId) => {
    const eventCandidateIds = await getEventCandidateIds(eventId)
    //console.log(eventCandidateIds)

    const eventInfo = await getEventById(eventId)
    //console.log(eventInfo)

    const allJrNumberDetails = await getAllJrNumberDetails()

    const jobApplications = await getAllCandidateJobApplication()

    const eventCandidateExcludeIds = [] // Id which already has job application
    jobApplications.forEach((jobApplication) => {
        if (jobApplication.Job_Req_ID === eventInfo.jr_number) {
            eventCandidateExcludeIds.push(jobApplication.National_ID)
        }
    })
    //console.log(eventCandidateExcludeIds)

    const eventCandidateNeedCreateProfile = []
    eventCandidateIds.forEach((candidateId) => {
        if (!eventCandidateExcludeIds.includes(candidateId)) {
            eventCandidateNeedCreateProfile.push(candidateId)
        }
    })
    //console.log(eventCandidateNeedCreateProfile)

    await Promise.all(eventCandidateNeedCreateProfile.forEach(async (candidateId) => {
        let latestJobProfile = {}
        jobApplications.forEach((jobApplication) => {
            if (jobApplication.National_ID === candidateId) {
                if (eventInfo.jr_number in allJrNumberDetails) {
                    jobApplication.AOC_Country = allJrNumberDetails[eventInfo.jr_number].aoc_country
                    jobApplication.Company = allJrNumberDetails[eventInfo.jr_number].company
                    jobApplication.Location = allJrNumberDetails[eventInfo.jr_number].location
                    jobApplication.Job_Posting_Title = allJrNumberDetails[eventInfo.jr_number].title
                }
                latestJobProfile = { ...jobApplication }
            }
        })
        latestJobProfile.Job_Req_ID = eventInfo.jr_number
        // Add a flag to sync data to workday
        latestJobProfile.sync_to_workday = true
        // Duplicate latest job profile to event jr_number
        await addCandidateJobApplication(candidateId, eventInfo.jr_number, latestJobProfile)
    }))
}

const uploadAssessmentResultEnglishExam = async (eventId, assessmentResultEnglishExam) => {
    let result = {
        hasError: false,
        message: ""
    }

    try {
        //console.log(assessmentResultEnglishExam)
        const csvLines = assessmentResultEnglishExam.split("\n")
        //console.log(csvLines)
        const dataLines = []
        // const titleLine = csvLines[0].split(',')
        //console.log(titleLine)
        delete csvLines[0]
        csvLines.forEach((line) => {
            if (line.length > 0) {
                dataLines.push(line.split(',').map(e => { return e.trim() }))
            }
        })
        //console.log(dataLines)

        const eventCandidateNumbers = await getAllCandidateNumberFromEvent(eventId)
        //console.log(eventCandidateNumbers)

        let notExistingCandidates = [];
        for (const line of dataLines) {
            //console.log(line)
            console.log(">>>", line);
            const candidateNumber = line[0] + line[1].padStart(4, "0")
            const englishExamResult = {
                score: line[2],
                passing_score: line[3],
                total: line[4],
                status: line[5]
            }

            let c = eventCandidateNumbers.find((e) => e.candidate_number === candidateNumber );

            if ( c ) {
                const candidateId = c.candidate_id;

                if ( candidateId && candidateId !== '' ) {
                    await setCandidateAssessmentResultEnglishExam(candidateId, eventId, {
                        ...englishExamResult,
                        overall_score: {
                            status: line[5] ?? "",
                            comment: line[6] ?? ""
                        }
                    })
                    // if (line[6] !== '') {
                        const assessmentComment = {
                            id: new Date().getTime(),
                            comment: line[6],
                            module: "English Exam",
                            overall_score: line[5],
                            created_by: "uploadResult",
                            created_at: getTime(),
                        }
                        setCandidateAssessmentResultComment(candidateId, eventId, assessmentComment);
                    // }
                }
            } else {
                notExistingCandidates.push(candidateNumber);
            }
        }

        console.log(notExistingCandidates);

        if ( notExistingCandidates.length > 0 ) {
            result.hasError = true;
            result.message = `The following candidates # are invalid (${arrayToString(notExistingCandidates)})`
        } else {
            result.message = `Successfully saved the uploaded results`
        }
    } catch(error) {
        result.hasError = true;
        result.message = "Failed to upload assessment results.";
        console.error('uploadAssessmentResultEnglishExam', error);
    }

    console.log(result);

    return result;
}

const uploadAssessmentResultGroupDynamic = async (eventId, assessmentResultGroupDynamic) => {
    let result = {
        hasError: false,
        message: ""
    }
    
    try {
        //console.log(assessmentResultGroupDynamic)
        const csvLines = assessmentResultGroupDynamic.split("\n")
        //console.log(csvLines)
        const dataLines = []
        const titleLine = csvLines[0].split(',')
        //console.log(titleLine)
        const columnDef = {
            eventId: null,
            candidateNo: null,
            totalScore: null,
            passingScore: null
        }
        titleLine.forEach((column, index) => {
            switch (column) {
                case "EVENT ID":
                    columnDef.eventId = index
                    break
                case "CANDIDATE NO.":
                    columnDef.candidateNo = index
                    break
                case "TOTAL POINTS":
                    columnDef.totalScore = index
                    break
                case "PASSING SCORE":
                    columnDef.passingScore = index
                    break
                default:
                    break
            }
        })
        //console.log(columnDef)
        const interviewerList = []
        titleLine.every((currentValue, id) => {
            if (id > 1 && id < columnDef.totalScore) {
                interviewerList.push({
                    name: currentValue,
                    scoreColumn: id,
                    commentColumn: id + columnDef.totalScore
                })
            }

            if (id >= columnDef.totalScore - 1) {
                return false
            }
            return true
        })
        //console.log(interviewerList)

        delete csvLines[0]
        csvLines.forEach((line) => {
            if (line.length > 0) {
                dataLines.push(line.split(',').map(e => { return e.trim() }))
            }
        })
        //console.log(dataLines)

        const eventCandidateNumbers = await getAllCandidateNumberFromEvent(eventId)
        //console.log(eventCandidateNumbers)

        let notExistingCandidates = [];
        for (const line of dataLines) {
            //console.log(line)
            const candidateNumber = line[columnDef.eventId] + line[columnDef.candidateNo].padStart(4, "0")
            const interviewerInfo = []
            interviewerList.forEach((interviewer) => {
                interviewerInfo.push({
                    name: interviewer.name,
                    score: line[interviewer.scoreColumn],
                    comment: line[interviewer.commentColumn]
                })
            })
            const groupDynamicResult = {
                total_score: line[columnDef.totalScore],
                passing_score: line[columnDef.passingScore],
                status: (line[columnDef.totalScore] >= line[columnDef.passingScore]) ? "Passed" : "Failed",
                interviewerInfo: interviewerInfo
            }

            let c = eventCandidateNumbers.find((e) => e.candidate_number === candidateNumber );

            if ( c ) {
                const candidateId = c.candidate_id;
                
                if ( candidateId && candidateId !== '' ) {
                    await setCandidateAssessmentResultGroupDynamic(candidateId, eventId, groupDynamicResult)
                    const createAt = new Date().getTime()
                    if (interviewerInfo.length > 0) {
                        interviewerInfo.forEach((interviewer, index) => {
                            // if (interviewer.comment !== '') {
                                const assessmentComment = {
                                    id: createAt + index,
                                    comment: interviewer.comment,
                                    module: "Group Dynamic",
                                    overall_score: Number(line[columnDef.totalScore]) >= line[columnDef.passingScore] ? "Passed" : "Failed",
                                    created_by: "uploadResult - " + interviewer.name,
                                    created_at: getTime(),
                                }
                                //console.log(assessmentComment)
                                setCandidateAssessmentResultComment(candidateId, eventId, assessmentComment);
                            // }
                        })
                    }
                }
            } else {
                notExistingCandidates.push(candidateNumber);
            }
        }

        if ( notExistingCandidates.length > 0 ) {
            result.hasError = true;
            result.message = `The following candidates # are invalid (${arrayToString(notExistingCandidates)})`
        } else {
            result.message = `Successfully saved the uploaded results`
        }
    } catch(error) {
        result.hasError = true;
        result.message = "Failed to upload assessment results."
        console.error('uploadAssessmentResultGroupDynamic', error)
    }

    console.log(result);

    return result;
}


const getCandidateEventAssessmentOverall = async (candidateId, eventId) => {
    const overallResult = {
        final_result: "Pending"
    }
    const listFinalResult = []
    const eventDetails = await getEventById(eventId)
    //console.log(eventDetails.event_assessment_module)
    const labelModuleName = {
        "Measurements": "measurement",
        "Document Check": "document_check",
        "Catwalk": "catwalk",
        "English Examinations": "english_exam",
        "Group Dynamics": "group_dynamic",
        "1:1 Interview": "one_to_one_interview"
    }

    const labelFunction = {
        "measurement": getCandidateEventAssessmentMeasurement,
        "document_check": getCandidateEventAssessmentDocumentCheck,
        "catwalk": getCandidateEventAssessmentCatwalk,
        "english_exam": getCandidateEventAssessmentEnglishExam,
        "group_dynamic": getCandidateEventAssessmentGroupDynamic,
        "one_to_one_interview": getCandidateEventAssessmentOneToOneInterview
    }

    for (const assessmentModule of eventDetails.event_assessment_module) {
        overallResult[labelModuleName[assessmentModule]] = null
        const result = await labelFunction[labelModuleName[assessmentModule]](candidateId, eventId)
        let assessmentResult = ""
        if (result !== undefined) {
            //console.log(result)
            switch (labelModuleName[assessmentModule]) {
                case "measurement":
                    overallResult.measurement = result.overall_score.status
                    assessmentResult = overallResult.measurement
                    listFinalResult.push(overallResult.measurement)
                    break
                case "document_check":
                    overallResult.document_check = result.overall_score.status
                    assessmentResult = overallResult.document_check
                    listFinalResult.push(overallResult.document_check)
                    break
                case "catwalk":
                    let catwalkResult = ""
                    switch (result.overall_score.toUpperCase()) {
                        case "A":
                        case "B":
                        case "C":
                            catwalkResult = "Passed"
                            break
                        case "D":
                            catwalkResult = "Failed"
                            break
                        default:
                            catwalkResult = "KIV"
                            break
                    }
                    overallResult.catwalk = catwalkResult
                    assessmentResult = overallResult.catwalk
                    listFinalResult.push(overallResult.catwalk)
                    break
                case "english_exam":
                    overallResult.english_exam = result.status
                    assessmentResult = overallResult.english_exam
                    listFinalResult.push(overallResult.english_exam)
                    overallResult.english_exam_score = result.score + "/" + result.total
                    break
                case "group_dynamic":
                    overallResult.group_dynamic = result.status
                    assessmentResult = overallResult.group_dynamic
                    listFinalResult.push(overallResult.group_dynamic)
                    //console.log(result)
                    overallResult.group_dynamic_score = result.total_score + "/" + result.interviewerInfo.length
                    break
                case "one_to_one_interview":
                    overallResult.one_to_one_interview = result.overall_score.status
                    assessmentResult = overallResult.one_to_one_interview
                    listFinalResult.push(overallResult.one_to_one_interview)
                    break
                default:
                    break
            }
        }
        else {
            overallResult[labelModuleName[assessmentModule]] = "Pending"
        }

        if (assessmentResult !== "") {
            if (overallResult.final_result === "Passed") {
                switch (assessmentResult) {
                    case "KIV":
                        overallResult.final_result = "KIV"
                        break
                    case "Failed":
                        overallResult.final_result = "Failed"
                        break
                    default:
                        break
                }
            }
            else if (overallResult.final_result === "KIV") {
                switch (assessmentResult) {
                    case "Failed":
                        overallResult.final_result = "Failed"
                        break
                    default:
                        break
                }
            }
        }
    }

    if (listFinalResult.length > 0) {
        if (listFinalResult.includes("Failed")) {
            overallResult.final_result = "Failed"
        } else if (listFinalResult.includes("KIV")) {
            overallResult.final_result = "KIV"
        } else if (listFinalResult.length !== eventDetails.event_assessment_module.length) {
            overallResult.final_result = "Pending"
        } else {
            overallResult.final_result = "Passed"
        }
    }

    //console.log(overallResult)
    return overallResult;
}

/**
 * Removes undefined values, since undefined values will return error upon saving it to firestore
 * @param {*} obj 
 * @returns 
 */
const preReadyData = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => preReadyData(item));
    }

    return Object.keys(obj).reduce((result, key) => {
        const value = obj[key];
        result[key] = preReadyData(value);
        return result;
    }, {});
}

const walkinCandidateRegistration = async (eventId, newCandidate) => {
    let response = {
        status: "",
        message: ""
    }
    let isValid = true
    //console.log(eventId)
    //console.log(newCandidate)
    console.log("okay")
    const eventInfo = await getEventById(eventId)
    console.log("okay")
    if (isValid) {
        if (eventInfo === undefined) {
            isValid = false
            response = {
                status: "401",
                message: "Invalid event"
            }
        }
        //console.log(eventInfo)
    }

    const today = new Date()
    newCandidate["application_date"] = today.getFullYear()
        + "-"
        + (today.getMonth() + 1).toString().padStart(2, "0")
        + "-"
        + today.getDate().toString().padStart(2, "0")

    const nationalId = await checkIfNationalIdExists(newCandidate.national_id);

    const candidateID = nationalId ?? newCandidate.national_id;

    newCandidate['candidate_id'] = candidateID;

    if (isValid) {
        const candidateExists = await checkIfCandidateExists(candidateID);

        const jrNumberDetails = await getJrNumberDetailsByJr(eventInfo.jr_number);

        newCandidate["aoc_country"] = jrNumberDetails.aoc_country
        newCandidate["job_title"] = jrNumberDetails.title
        newCandidate["job_company"] = jrNumberDetails.company
        newCandidate["job_location"] = jrNumberDetails.location

        if (!candidateExists) {
            // Candidate not found, create new candidate
            newCandidate["jr_number"] = [eventInfo.jr_number]
            const regStatus = await registerWalkInCandidate(newCandidate);

            if ( regStatus === "Confirmed" ) {
                response = {
                    status: "200",
                    id: candidateID,
                    message: "Candidate created"
                }
            } else {
                response = {
                    status: "301",
                    id: candidateID,
                    message: regStatus
                }
            }
        } else {
            const candidateInfo = await getCandidateById(candidateID);

            await updateRegistrationData( candidateID, newCandidate );

            // Candidate found. Check status
            //console.log(candidateInfo)
            const registrationStatus = getCandidateWalkInRegistrationStatus(candidateInfo);

            //console.log(registrationStatus)
            if (registrationStatus !== "Pending") {
                const exceptionStatus = "Exception - " + registrationStatus
                await updateCandidateRegistrationStatus(candidateID, exceptionStatus)
                
                response = {
                    status: "301",
                    id: candidateID,
                    message: exceptionStatus
                }
            }
            else {
                response = {
                    status: "200",
                    id: candidateID,
                    message: "Job application created"
                }
            }
            //console.log(candidateInfo)

            const jobApplication = {}
            jobApplication['AOC_Country'] = newCandidate.aoc_country ?? ''
            jobApplication['Location'] = newCandidate.job_location ?? ''
            jobApplication['Candidate_Creation_Date'] = newCandidate.application_date ?? '';
            jobApplication['Candidate_ID'] = ''
            jobApplication['Candidate_Stage'] = 'Review'
            jobApplication['City'] = newCandidate.city ?? '';
            jobApplication['Company'] = newCandidate.job_company ?? ''
            jobApplication['Country'] = newCandidate.country ?? '';
            jobApplication['Date_of_Birth'] = newCandidate.dob ?? '';
            jobApplication['Email'] = newCandidate.email ?? '';
            jobApplication['First_Name'] = newCandidate.first_name ?? '';
            jobApplication['Gender'] = newCandidate.gender ?? '';
            jobApplication['Job_Posting_Title'] = newCandidate.job_title ?? ''
            jobApplication['Job_Req_ID'] = eventInfo.jr_number ?? '';
            jobApplication['Language_Spoken'] = newCandidate.language_spoken ?? []
            jobApplication['Last_Name'] = newCandidate.last_name ?? '';
            jobApplication['LinkedIn_Profile'] = newCandidate.linkin_url_profile ?? ""
            jobApplication['National_ID'] = candidateID
            jobApplication['Nationality'] = newCandidate.nationality ?? '';
            jobApplication['Phone_Number'] = newCandidate.phone_number ?? '';
            if (newCandidate.work_experience.length > 0) {
                jobApplication['work_experiences'] = { ...newCandidate.work_experience }
            }
            else {
                jobApplication['work_experiences'] = []
            }
            jobApplication['Worked_AA_Before'] = newCandidate.have_you_worked_at_airasia_before ?? "NA"
            jobApplication['Job_Source'] = newCandidate.how_you_heard_about_this_job ?? "NA"
            jobApplication['is_walkin'] = true;
            
            const data = preReadyData(jobApplication);

            //console.log(jobApplication)
            await addCandidateJobApplication(candidateID, eventInfo.jr_number, data)
        }

        await addEventToCandidate(eventId, candidateID)
        await addCandidateToEvent(candidateID, eventId)
        await assignCandidateNumberToEventCandidate(candidateID, eventId)
        // Set email sent to prevent email sending to walk in candidate
        await setEmailSent(candidateID, eventId)
    }

    //console.log(response)
    return response
}

const overwriteCandidateBlacklistStatus = async (candidateId, candidateBlacklistDetails) => {
    const blacklistStatus = candidateBlacklistDetails.is_blacklist
    await updateCandidateBlacklistStatus(candidateId, blacklistStatus)
    await addCandidateBlacklistHistory(candidateId, candidateBlacklistDetails)
}

const overwriteCandidateCoolingPeriod = async (candidateId, coolingPeriodDetails) => {
    const coolingPeriod = coolingPeriodDetails.cooling_period
    await updateCandidateCoolingPeriod(candidateId, coolingPeriod)
    await addCandidateCoolingPeriodHistory(candidateId, coolingPeriodDetails)
}

/**
 * Uploads file into firebase storage and returns its public url
 * @param {string} path firebase storage folder path `eg. event/${eventId}/files`
 * @param {string} fileName name of the file `eg. birth-cert.pdf`
 * @param {File} file the attachment
 * @returns {Promise<string>} image url
 */
const uploadFileToStorage = async (path, fileName, file) => {
    try {
        if (!file || !path || !fileName) {
            console.log("path, fileName and file is required");
            return "";
        }

        const storageRef = ref(storage, `${path}/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        await uploadTask;

        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        return downloadURL;
    } catch (error) {
        console.log(error);
        return "";
    }
}

const triggerAutoSendInvitationEmail = async (eventId) => {
    let isValid = true

    if (isValid) {
        const isEmailTemplateFinal = await getEventEmailTemplateFinalStatus(eventId)
        //console.log(isEmailTemplateFinal)
        if (isEmailTemplateFinal !== true) {
            // Template not yet finalized, not sending
            isValid = false
        }
    }
    let candidateIds = []
    let candidateNumbers = []
    let emailSentList = []
    let filteredCandidateIds = []
    if (isValid) {
        candidateIds = await getEventCandidateIds(eventId)
        if (candidateIds.length > 0) {
            candidateNumbers = await getAllCandidateNumberFromEvent(eventId)
            //console.log(candidateNumbers)
            emailSentList = await getEmailSentList(eventId)
            //console.log(emailSentList)
            filteredCandidateIds = candidateIds.filter(n => !emailSentList.includes(n))
            if (filteredCandidateIds.length === 0) {
                // All email has been sent
                isValid = false
            }
            //console.log(filteredCandidateIds)
        }
        else {
            // no candidate being added to event
            isValid = false
        }
    }

    let candidateList = []
    let candidateInfoList = []
    if (isValid) {
        candidateList = await getAllCandidate()
        //console.log(candidateList)
        for (const candidateId of filteredCandidateIds) {
            candidateList.every(async (candidateInfo) => {
                if (candidateInfo.id === candidateId) {
                    let candidateNumber = ""
                    candidateNumbers.every((objCandidateNumber) => {
                        if (objCandidateNumber.candidate_id === candidateId) {
                            candidateNumber = objCandidateNumber.candidate_number
                            return false
                        }
                        return true
                    })

                    candidateInfoList.push({
                        first_name: candidateInfo.first_name,
                        candidate_number: candidateNumber,
                        email: candidateInfo.email,
                        candidate_id: candidateInfo.id
                    })
                    return false
                }
                return true
            })
        }
        //console.log(candidateInfoList)
    }

    // Decrypt all email before sendEmail
    for(let data of candidateInfoList){
        data.email = await decryptData(data.email)
    }

    for (let i in candidateInfoList) {
        candidateInfoList[i]["token"] = await generateAcceptInvitationToken(eventId, candidateInfoList[i].candidate_id)
    }
    console.log("candidateInfoList >", candidateInfoList);

    let eventInfo = {}
    if (isValid) {
        const eventDetails = await getEventById(eventId)
        const event_date_time = eventDetails.event_date_time.split(" ")
        //console.log(eventDetails)
        //console.log(event_date_time)
        eventInfo["event_date"] = event_date_time[0]
        eventInfo["event_time"] = event_date_time[1]
        eventInfo["event_venue"] = eventDetails.event_venue
        eventInfo["event_id"] = eventId
        //console.log(eventInfo)
    }

    let emailTemplate = ""
    if (isValid) {
        const eventEmailTemplate = await getEventEmailTemplateById(eventId)
        //console.log(eventEmailTemplate)

        if ('email_template' in eventEmailTemplate) {
            emailTemplate = eventEmailTemplate.email_template

        }
        else {
            // Email Template not defined
            isValid = false
        }

        if ('subject' in eventEmailTemplate) {
            eventInfo["email_subject"] = eventEmailTemplate.subject
        }
        else {
            // Email Subject not defined
            isValid = false
        }
    }

    if (isValid) {
        const token = await generateSendEmailToken(eventInfo, candidateInfoList, emailTemplate)
        //console.log(token)
        if (token !== "") {
            // Call to cloud function with token to process send invitation email
            sendEmail(token)
        }
    }
}

const getCandidateEventAttended = async (candidateId) => {
    // let candidateEventAttended = []
    // const candidateJobApplications = await getCandidateJobApplicationById(candidateId)
    // const candidateInfo = await getCandidateById(candidateId)
    // // console.log("candidateJobApplications >>>",candidateJobApplications)
    // // console.log("candidateInfo >",candidateInfo)
    // if (candidateJobApplications.length > 0) {
    //     let i = 1
    //     for (const jobApplication of candidateJobApplications) {
    //         const eventId = await getEventByJrNumber(jobApplication.Job_Req_ID)
    //         let candidateNumber = ""
    //         let assessmentStatus = ""
    //         if (eventId !== "") {
    //             candidateNumber = await getCandidateNumberFromEvent(candidateId, eventId)
    //         }

    //         if (candidateNumber !== "") {
    //             const candidateAssessmentResult = await getCandidateEventAssessmentOverall(candidateId, eventId)
    //             assessmentStatus = candidateAssessmentResult.final_result
    //         }

    //         candidateEventAttended.push({
    //             id: i,
    //             event_id: eventId,
    //             application_date: jobApplication.Candidate_Creation_Date,
    //             jr_number: jobApplication.Job_Req_ID,
    //             job_description: jobApplication.Job_Posting_Title,
    //             job_location: jobApplication.Location,
    //             company_name: jobApplication.Company,
    //             source: candidateInfo.source,
    //             workday_status: jobApplication.Candidate_Stage,
    //             candidate_number: candidateNumber,
    //             assessment_status: assessmentStatus
    //         })
    //         i++
    //     }
    // }

    const result = await apiService.getCandidateJobApplications({candidateId});

    console.log(result.data);

    const candidateEventAttended = result.data?.job_applications ?? [];

    // console.log(candidateEventAttended)
    return candidateEventAttended
}


/**
 * get all event attended id from candidate
 * @param {string} candidateId candidateId
 * @returns {Promise<Array>} image url
 */
const getCandidateEventAttendedId = async (candidateId) => {
    const result = await apiService.getCandidateJobApplications({candidateId});
    const candidateEventAttended = result.data?.job_applications ?? [];
    let eventIds = []
    if (candidateEventAttended.length > 0){
        eventIds = candidateEventAttended.map((event) => event.event_id)
    }
    return eventIds
}

export {
    getEventDetailsPageInfoByEvenId,
    getCandidateEventAssessmentDocumentCheck,
    setCandidateEventAssessmentDocumentCheck,
    getCandidateEventAssessmentMeasurement,
    setCandidateEventAssessmentMeasurement,
    getCandidateEventAssessmentCatwalk,
    setCandidateEventAssessmentCatwalk,
    getCandidateEventAssessmentOneToOneInterview,
    setCandidateEventAssessmentOneToOneInterview,
    getCandidateEventAssessmentEnglishExam,
    uploadAssessmentResultEnglishExam,
    getCandidateEventAssessmentGroupDynamic,
    uploadAssessmentResultGroupDynamic,
    getCandidateEventAssessmentComment,
    setCandidateEventAssessmentComment,
    autoCreateCandidateJobApplication,
    getCandidateEventAssessmentOverall,
    walkinCandidateRegistration,
    overwriteCandidateBlacklistStatus,
    uploadFileToStorage,
    overwriteCandidateCoolingPeriod,
    triggerAutoSendInvitationEmail,
    getCandidateEventAttended,
    getDashboardDetails,
    getCandidateEventAttendedId
}