
/**
 * Converts array of objects into csv string
 * @param {*} objects // array of objects
 */
export const objectToCSV = (options, objects) => {
  
  let csv = "";

  const header = options.map((option) => `${option.label}`);
  const keys = options.map((option) => `${option.key}` );

  csv += `${header}\r\n`;

  for( const object of objects ) {
    let value = [];
    for( const key of keys) {
      value.push(object[key] ?? "-")
    }
    csv += `${value.join(",")}\r\n`
  }

  return csv;
}

