import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { updateCandidateRegistrationStatus } from "../lib/db_candidate";
import { checkIfCandidateExistsInEvent, getEventById } from "../lib/db_event";
import { getInvitationTokenDetails, setInvitationTokenToHasProcessed } from "../lib/db_token";
import { parseEventDateTime } from "../utils/eventDateTime";
import PublicPageLayout from "./layout/PublicPageLayout";
import DownTimePlaceholder from "./shared/DownTimePlaceholder";
import { getAuth, signInAnonymously } from "firebase/auth";

const ConfirmAttendancePage = () => {
  const params = useParams();
  const { token } = params;


  const [downTimeDetails, setDownTimeDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // const [event, setEvent] = useState({});

  const renderMissedOut = (event) => {
    setDownTimeDetails({
      title: "Oh no!",
      description: <>
        Oops, looks like you have missed the timeframe in confirming your attendance for the {event.event_name ?? ""}. Please follow us on Instagram <a href="https://www.instagram.com/flyairasia/?hl=en" targe="_blank" rel="noopener noreferrer">@flyairasia</a> for news on the next Cabin Crew hiring drive.
      </>
    });
  }

  const dateToDDMMYYYY = (dateString) => {
    const year = dateString.split("-")[0];
    const month = dateString.split("-")[1];
    const day = dateString.split("-")[2];

    return `${day}/${month}/${year}`
  }

  const renderError = () => {
    setDownTimeDetails({
      title: "Oh no!",
      description: <>
        Oops! Something went wrong. It looks like the page you are looking for isn't available right now.
      </>
    });
  }

  const renderSuccess = (event) => {
    setDownTimeDetails({
      imageUrl: "/images/takeOff.png",
      title: "You're ready for take-off!",
      description: <>
        Thank you for confirming your attendance and we look forward to seeing you at {event.event_name} on {dateToDDMMYYYY(event.event_date)}. See you!
      </>
    });
  }

  const renderAlreadyConfirmed = (event) => {
    setDownTimeDetails({
      imageUrl: "/images/takeOff.png",
      title: "We're excited too :)",
      description: <>
        We know you're excited about the {event.event_name} but looks like you have clicked the confirmation button more than once. We assure you that your confirmation is well received and see you!
      </>
    });
  }

  const isPassed = (date) => {
    if ( date ) {
      const eventDate = new Date(date);
      const currentDate = new Date();

      return eventDate < currentDate;
    } else {
      return false;
    }
  }

  useEffect(() => {
    let isMounted = true

    const loadDetails = async () => {
      setIsLoading(true);
      const auth = getAuth()
      
      // Sign In Anonymous to access to Cloud Firestore
      signInAnonymously(auth).then(async () => {
        try {
          const tokenDetails = await getInvitationTokenDetails(token);
          const eventID = tokenDetails.event_id;
          const candidateID = tokenDetails.candidate_id;
          const hasProcessed = tokenDetails.has_processed ?? false;

          const e = await getEventById(eventID);
          const jrNumber = e.jr_number;
          const eventIsActive = e.event_status === "Active";

          const eventDateTime = parseEventDateTime(e.event_date_time);

          if ( eventIsActive ) {
            const passed = isPassed(eventDateTime);

            if ( !passed ) {
              // checks if candidate is in the event
              const candidateExists = await checkIfCandidateExistsInEvent( eventID, candidateID );

              if ( candidateExists && jrNumber ) {
                // check if already attending
                // const attending = await checkIfCandidateIsAttending(candidateID, jrNumber);

                if ( !hasProcessed ) {
                  await updateCandidateRegistrationStatus( candidateID, "Confirmed" );
                  await setInvitationTokenToHasProcessed(token);
                  if(isMounted) renderSuccess(e);
                } else {
                  if(isMounted) renderAlreadyConfirmed(e);
                }
              } else {
                if(isMounted) renderMissedOut(e);
              }
            } else {
              if(isMounted) renderMissedOut(e);
            }
          } else {
            if(isMounted) renderMissedOut(e);
          }
        
        } catch(e) {
          console.log(`${e}`);
          if(isMounted) renderError();
        }
      }).catch((error) => {
        console.log(`${error}`);
        if(isMounted) renderError();
      }).finally(async () => {
          if(isMounted) setIsLoading(false);
          await auth.signOut()
          console.log("test");
        }
      )
    }

    loadDetails();
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return(
    <PublicPageLayout contentWidth="lg" isLoading={isLoading}>
      { !isLoading && (
        <DownTimePlaceholder 
          maxWidth="md"
          imageUrl={downTimeDetails?.imageUrl}
          title={downTimeDetails?.title}
          description={downTimeDetails?.description}
        />
      )}
    </PublicPageLayout>
  )
}

export default ConfirmAttendancePage;