import { Delete } from "@mui/icons-material";
import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useRef, useState } from "react";
import ReCAPTCHA from 'react-google-recaptcha'

const CustomForm = ( props ) => {
  const { isLoading, config, values, onUpdate, setKey, removeItemClick, handleSubmit } = props;

  const [formErrors, setFormErrors] = useState({});

  const recaptcha = useRef();

  const handleOnChange = ( e ) => {
    const value = `${e.target.value}`;
    const name = `${e.target.name}`;

    if ( name ) {
      const error = formErrors[name];
      if ( error ) {
        setFormErrors({
          ...formErrors,
          [name] : ""
        });
      } 
      onUpdate({
        ...values,
        [name] : value
      });
    }
  }

  const handleOnChangeDate = (e) => {
    const date = e.target.value
    const name = e.target.name;
    let days
    let months
    if (name && (date !== null && !isNaN(date.$D) && !isNaN(date.$M) && !isNaN(date.$y) && !isNaN(date.$H) && !isNaN(date.$m))) {
      const error = formErrors[name];
      if(error){
        setFormErrors({
          ...formErrors,
          [name] : ""
        });
      }
        date.$D < 10 ? days = `0${date.$D}` : days = date.$D
        date.$M < 10 ? months = `0${date.$M + 1}` : months = date.$M + 1
        let value
        if(name === "dob") {value = `${date.$y}-${months}-${days}`}
        else {value = `${date.$y}-${months}`}
        onUpdate({
          ...values,
          [name]: value
        })
    } else {
      return
    }
  }

  const validateFields = () => {
    let conditions = [];

    let error = {};
    for( const c of config ) {
      const tag = c.tag ?? "input";
      const name = c.name ?? "";
      const label = c.label ?? name ?? "";
      const value = values[name] ?? "";
      const requiredIf = c.required_if ?? "";
      const requiredIfValue = c.required_if_value ?? "";
      const requiredI = values[requiredIf] === requiredIfValue;
      const required = values[requiredIf] ? requiredI :  c.required ?? false;

      if ( required ) {
        if ( tag === "list_item" ) {
          error = {
            ...error,
            [name] : value.length === 0 ? `"${label}" is required` : ""
          }
        } else {
          error = {
            ...error,
            [name] : !value ? `"${label}" is required.` : ""
          };
        }
      }

      if ( value ) {
        conditions.push(true);
      } else {
        if ( required ) {
          conditions.push(false);
        } else {
          conditions.push(true);
        }
      }
    }

    setFormErrors(error);

    if (conditions.length === 0) {
      return false; // Array is empty
    }
  
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i] !== true) {
        return false; // Array contains at least one false value
      }
    }
  
    return true;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const proceed = validateFields();

    if ( proceed ) {
      let token = "";
      if (recaptcha.current) {

        const captchaValue = recaptcha.current.getValue();

        if (!captchaValue) {
          alert('Please verify the reCAPTCHA!');
          return;
        } else {
          token = captchaValue;
        }

        recaptcha.current.reset();
      }

      // make form submission
      handleSubmit({
        ...values,
        recaptcha_token: token
      });
      
    }
  }


  const OverflowTypography = styled(Typography)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;

  return(
    <form onSubmit={onSubmit} method="post" noValidate>
      <Box>
        <Grid container spacing={2}>
          {config.map((item, index) => {
            const name = item.name ?? "name";
            const label = item.label ?? "";
            const placeholder = item.placeholder ?? "";
            const defaultValue = item.defaultValue ?? "";
            const type = item.type ?? "text";
            const tag = item.tag ?? "input";
            const options = item.options ?? [];
            const requiredIf = item.required_if ?? "";
            const requiredIfValue = item.required_if_value ?? "";
            const shown = values[requiredIf] === requiredIfValue;
            const hidden = values[requiredIf] ? !shown : false;
            const description = item.description ?? ""

            const required = item.required ?? false;
            const buttonText = item.buttonText ?? "";
            const hasButton = buttonText !== "";
            const multiline = item.multiline ?? false;
            const listIcon = item.listIcon ?? "";
            const size = item.size ?? "large";

            const itemNameField = item.item_name_field ?? "";

            const key = `personal-info-grid-item-${index}`;

            return (
              <Grid key={key} item xs={item.xs ?? 12} md={item.md ?? 12} sx={ hidden ? { display: "none"} : {}}>
                { tag === "title" && (
                  <>
                    <Box display="flex"
                      alignItems="center"
                      justifyContent="space-between">
                      <Typography variant="h5" fontSize="20px" lineHeight="40px" fontWeight="medium" color="#000">{label}</Typography>
                      { hasButton && (
                        <Button
                          size="small"
                          px={2}
                          variant="contained"
                          color="success"
                          onClick={() => setKey(name)}
                          disabled={isLoading}>
                          {buttonText}
                        </Button>
                      )}
                    </Box>
                    {description.length > 0 && (<Typography variant="caption" fontSize="12px" color="#666">{description}</Typography>)}
                  </>
                )}
                { tag === "label" && (
                  <InputLabel htmlFor={name}>{label}</InputLabel>
                )}
                { tag === "div" && (
                  <Box bgcolor="#ebebeb" height="1px" my={3}></Box>
                )}
                { (tag === "input" && type !== "date" && type !== "month") && (
                  <TextField
                    fullWidth
                    name={name}
                    id={name}
                    onChange={handleOnChange}
                    value={values[name] ?? ""}
                    placeholder={placeholder}
                    label={label}
                    type={type}
                    multiline={multiline}
                    maxRows={5}
                    size={size}
                    required={required}
                    error={formErrors[name] ? true : false }
                    disabled={isLoading}
                  />
                )}
                { (tag === "input" && type === "date") && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker onChange={(date) => handleOnChangeDate({target: { name: name, value: date }})} format='DD/MM/YYYY' sx={{ width: "100%" }} slotProps={{textField: {required:required, error:formErrors[name] ? true : false, label: label}}}/>
                  </LocalizationProvider>
                )}
                { (tag === "input" && type === "month") && (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker views={['year', 'month']} onChange={(date) => handleOnChangeDate({target: { name: name, value: date }})} format='MM/YYYY' sx={{ width: "100%" }} slotProps={{textField: {required:required, error:formErrors[name] ? true : false, label: label}}}/>
                  </LocalizationProvider>
                )}
                { tag === "select" && (
                  <FormControl fullWidth size={size} required={required}>
                    <InputLabel>{label}</InputLabel>
                    <Select
                      value={values[name] ?? defaultValue}
                      label={label}
                      name={name}
                      id={name}
                      onChange={handleOnChange}
                      error={formErrors[name] ? true : false }
                      disabled={isLoading}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: '200px', // Set the maximum height here
                          },
                        },
                      }}>
                      { options.map((option, index) => {
                        const keyOption = `option-${name}-item-${index}`;
                        return(
                          <MenuItem key={keyOption} value={option.value}>{option.label}</MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
                { tag === "list_item" && (<>
                  <Grid container spacing={2}>
                    { (values[name] ?? []).map((e, index) => {
                      const key = `item-${index}`;

                      return(
                        <Grid key={key} item xs={4}>
                          <Box display="flex" alignItems="center" bgcolor="#f9f9f9" height="60px" borderRadius={2} p={2}>
                            <Box display="flex" alignItems="center" mr={2}>
                              {listIcon}
                            </Box>
                            <OverflowTypography width="100%" color="#999" fontSize="14px">
                              {e[itemNameField]}
                            </OverflowTypography>
                            { removeItemClick && (
                              <IconButton onClick={() => removeItemClick(name, index)} disabled={isLoading}>
                                <Delete />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  { (values[name] ?? []).length === 0 && (
                    <Typography py={3} color={formErrors[name] ? "error" : "#000"}>
                      { formErrors[name] ? `${formErrors[name]}` : "Nothing added."}
                    </Typography>
                  ) }
                </>)}
              </Grid>
            );
          })}
        </Grid>
        <Box bgcolor="#ebebeb" height="1px" my={3}></Box>
        <Box pt={2} sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
          justifyContent: "center",
          gap: 2,
        }}>
          {
            process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
              <Box>
                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
              </Box>
            )
          }
          
          <Button
            size="large"
            variant="contained" 
            type="submit">
            Register
          </Button>
        </Box>
      </Box>
    </form>
  );
}

export default CustomForm;