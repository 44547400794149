import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import MenuTab from '../menu/MenuTab';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, Container, FormControl, Tab, Tabs, TextField, Typography, useTheme } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import DocumentCheck from './DocumentCheck';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Measurement from './Measurement';
import Catwalk from './Catwalk';
import { getCandidateEventAssessmentComment } from '../../lib/page';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Footer from '../menu/Footer';
import AddComment from './AddComment';
import OneInterview from './OneInterview';
import DisplayComment from './DisplayComment';
import OverallResult from './OverallResult';
import GroupDynamic from './GroupDynamic';
import EnglishExam from './EnglishExam';
import { getEventById, getEventInfoById } from '../../lib/db_event';
import { docCheckMY, docCheckIDN, docCheckPH, docCheckTHA } from './DocumentCheck/DocCheckConstant';
import { authenticateUser } from '../../lib/userAuthenticate';

function AssessmentPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const [docCheck, setDocCheck] = useState(docCheckMY)
    const [country, setCountry] = useState("")
    const [eventModule, setEventModule] = useState([])
    const [tabIndex, setTabIndex] = useState("1");
    const [candidate, setCandidate] = useState([])
    const [candidateDetails, setCandidateDetails] = useState({})
    const [isComment, setIsComment] = useState(false)
    const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false)
    const [initialComments, setInitialComments] = useState([
        {
            comment: "",
            module: "",
            overall_score: "",
            created_by: "",
            created_at: ""
        }
    ])
    const [user, setUser] = useState({
        username: "",
        url: ""
    })
    const { eventID } = useParams()

    const [eventNumber, setEventNumber] = useState("")
    const [bringCandidateNumber, setBringCandidateNumber] = useState(null)
    const [isLoading,setIsLoading] = useState(false)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    
    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        if(location.state === null){
            location.state = {
                name: searchParams.get('name'),
                displayPic: searchParams.get('displayPic'),
                candidateNumber: searchParams.get('candidateNumber'),
                candidateId: searchParams.get('candidateId'),
                candidate: searchParams.get('candidate')
            }
        }
        const getCandidate = async() => {
            setIsLoading(true)
            // const eventDetail = await getEventDetailsPageInfoByEvenId(eventID)
            const {
                candidates = [],
                eventInfo = {}
            } = await getEventInfoById(eventID);
            
            setCandidate(candidates)
            setEventNumber(eventInfo.event_id)
            setIsLoading(false)
        }
        getCandidate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        authenticateUser(setUser, location, navigate)
    }, [location, navigate])

    const [eventStatus, setEventStatus] = useState("");

    useEffect(() => {
        const getEvent = async () => {
            const eventDetails = await getEventById(eventID)
            setEventStatus(eventDetails.event_status);
            setEventModule(eventDetails.event_assessment_module)
            if(eventDetails.hiring_country === "Indonesia"){
                setDocCheck(docCheckIDN)
            } else if(eventDetails.hiring_country === "Philippines"){
                setDocCheck(docCheckPH)
            } else if(eventDetails.hiring_country === "Thailand"){
                setDocCheck(docCheckTHA)
            } else {
                setDocCheck(docCheckMY)
            }
            setCountry(eventDetails.hiring_country)
        }
        getEvent()
    }, [eventID])

    const handleChange = useCallback(async (e, val) => {
        setCandidateDetails({})
        setBringCandidateNumber(null)
        setInitialComments([
            {
                comment: "",
                module: "",
                overall_score: "",
                created_by: "",
                created_at: ""
            }
        ])
        if (val !== null) {
            setCandidateDetails(val)
            setBringCandidateNumber(val.candidate_number)
            const res = await getCandidateEventAssessmentComment(val.id, eventID)
            if (res.length !== 0) {
                setInitialComments(res)
            } else {
                setInitialComments([
                    {
                        comment: "",
                        module: "",
                        overall_score: "",
                        created_by: "",
                        created_at: ""
                    }
                ])
            }
        } else {
            setCandidateDetails({})
            setBringCandidateNumber(null)
            setInitialComments([
                {
                    comment: "",
                    module: "",
                    overall_score: "",
                    created_by: "",
                    created_at: ""
                }
            ])
        }
    }, [eventID])

    useEffect(() => {
        async function bringCandidate() {
            setTabIndex("7")
            let listCandidate = []
            listCandidate = candidate
            if(candidate.length === 0){
                listCandidate = location.state.candidate
            }
            if(!Array.isArray(listCandidate)){
                setTimeout(bringCandidate, 1000); 
            } else {
                const thisCandidate = listCandidate.find(obj => obj.id === location.state.candidateId)
                setBringCandidateNumber(location.state.candidateNumber)
                handleChange({}, thisCandidate)
                location.state.candidateNumber = undefined
                location.state.candidateId = undefined
            }
        }
        if (location.state.candidateNumber !== undefined && location.state.candidateId !== undefined) {
            bringCandidate()
        }
    }, [handleChange, location.state, candidate])

    useEffect(() => {
        async function getCommentAfter() {
            if (candidateDetails.id === null) {
                return
            }
            const res = await getCandidateEventAssessmentComment(candidateDetails.id, eventID)
            if (res.length !== 0) {
                setInitialComments(res)
            } else {
                setInitialComments([
                    {
                        comment: "",
                        module: "",
                        overall_score: "",
                        created_by: "",
                        created_at: ""
                    }
                ])
            }
            setIsComment(false)
        }
        if (isComment === true) {
            getCommentAfter()
        }
    }, [candidateDetails, eventID, isComment])

    const handleComment = () => {
        setIsCommentDialogOpen(!isCommentDialogOpen)
    }

    const handleChangeEditTabs = (e, activeTab) => {
        setTabIndex(activeTab)
    }

    const handleClickBack = () => {
        navigate(`/events/${eventID}`, {
            state: {
                name: user.username,
                displayPic: user.url,
            }
        })
    }

    const theme = useTheme();
    return (
        <div style={{ minHeight: "100vh" }}>
            <MenuTab username={user.username} url={user.url} />
            {!isLoading ? <Container disableGutters maxWidth="100%" component="main" sx={{ textAlign: "center", justifyContent: 'center', pl: `calc(${theme.spacing(7)} + 5px)`, marginBottom: "56px" }}>
                <Box p={2}>
                    <Box display={'flex'} sx={{float: "left", mb: -1}}>
                    <Typography fontWeight={500} color={"#C5C9D0"} onClick={handleClickBack} sx={{":hover": {cursor: "pointer"}}} >Event ID - {eventNumber} &nbsp; </Typography><Typography fontWeight={400}> &gt; Assessment</Typography>
                    </Box>
                    <Box m={1} sx={{ width: "100%", display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <Box m={1} sx={{ flex: 1, textAlign: 'left' }}>
                            <Typography variant='h5'>Assessment</Typography>
                        </Box>
                        <Box m={1} sx={{ flex: 4 }}>
                            <Box sx={{ textAlign: 'left', justifyContent: 'left' }}>
                                <FormControl variant='standard' sx={{ minWidth: 250 }}>
                                    <Autocomplete
                                        id='candidate'
                                        options={candidate}
                                        getOptionLabel={(option) => option.candidate_number}
                                        renderOption={(props, option, { selected }) => {
                                            if(bringCandidateNumber === option.candidate_number){
                                                selected = true
                                            }
                                            return (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginLeft: -10 }}
                                                    checked={selected}
                                                />
                                                {option.candidate_number}
                                            </li>
                                        )}}
                                        onChange={handleChange}
                                        renderInput={(params) => {
                                            if(bringCandidateNumber !== null){
                                                params.inputProps.value = bringCandidateNumber
                                            }
                                            return (
                                            <TextField {...params} label="Candidate Number" placeholder="e.g A010003"/>
                                        )}}
                                    />
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
                        <TabContext value={tabIndex}>
                            <Box m={1} sx={{ flex: 1, boxShadow: "0px 1px 4px 0px #00000029", height: "fit-content" }}>
                                <Box p={1} >
                                    <Tabs orientation='vertical' value={tabIndex} onChange={handleChangeEditTabs}>
                                        {eventModule.includes("Measurements") && <Tab disableRipple label={"Measurements"} value="1" />}
                                        {eventModule.includes("Document Check") && <Tab disableRipple label={"Document Check"} value="2" />}
                                        {eventModule.includes("Catwalk") && <Tab disableRipple label={"Catwalk"} value="3" />}
                                        {eventModule.includes("English Examinations") && <Tab disableRipple label={"English Examination"} value="4" />}
                                        {eventModule.includes("Group Dynamics") && <Tab disableRipple label={"Group Dynamic"} value="5" />}
                                        {eventModule.includes("1:1 Interview") && <Tab disableRipple label={"1-1 Interview"} value="6" />}
                                        <Tab disableRipple label={"OVERALL"} value="7" />
                                    </Tabs>
                                </Box>
                            </Box>
                            <Box m={1} sx={{ flex: 4 }}>
                                <Box width={"100%"} sx={{ p: 3, backgroundColor: "#EDEDF6", textAlign: "left", borderBottom: "4px solid #4B4FA6" }}>
                                    {candidateDetails.candidate_number ? <Typography variant='h4' color={"#4B4FA6"}><strong>{candidateDetails.candidate_number}</strong></Typography> : <Typography variant='h4' color={"#4B4FA6"}><strong>-</strong></Typography>}
                                    {candidateDetails.first_name ? <Typography variant='h6' color={"#4B4FA6"}>{candidateDetails.first_name} {candidateDetails.last_name}</Typography> : <Typography variant='h6' color={"#4B4FA6"}>Candidate Name</Typography>}
                                </Box>
                                <TabPanel value='1'>
                                    <Box sx={{ width: '100%' }}>
                                        <Measurement 
                                            candidateId={candidateDetails.id} 
                                            eventId={eventID} 
                                            username={user.username} 
                                            isComment={() => setIsComment(true)} 
                                            isCompleted={eventStatus === "Completed"} />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='2'>
                                    <Box sx={{ width: '100%' }}>
                                        <DocumentCheck 
                                            candidateId={candidateDetails.id} 
                                            eventId={eventID} 
                                            username={user.username} 
                                            isComment={() => setIsComment(true)}
                                            isCompleted={eventStatus === "Completed"}
                                            docCheck={docCheck} 
                                            country={country}
										/>

                                    </Box>
                                </TabPanel>
                                <TabPanel value='3'>
                                    <Box sx={{ width: '100%' }}>
                                        <Catwalk 
                                            candidateId={candidateDetails.id} 
                                            eventId={eventID} 
                                            username={user.username} 
                                            isComment={() => setIsComment(true)} 
                                            isCompleted={eventStatus === "Completed"}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='4'>
                                    <Box sx={{ width: '100%' }}>
                                        <EnglishExam 
                                            candidateId={candidateDetails.id} 
                                            eventId={eventID} 
                                            username={user.username}
                                            isComment={() => setIsComment(true)} 
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='5'>
                                    <Box sx={{ width: '100%' }}>
                                        <GroupDynamic 
                                            candidateId={candidateDetails.id} 
                                            eventId={eventID}
                                            username={user.username}
                                            isComment={() => setIsComment(true)} 
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='6'>
                                    <Box sx={{ width: '100%' }}>
                                        <OneInterview 
                                            candidateId={candidateDetails.id} 
                                            eventId={eventID} 
                                            username={user.username} 
                                            isComment={() => setIsComment(true)} 
                                            isCompleted={eventStatus === "Completed"}
                                        />
                                    </Box>
                                </TabPanel>
                                <TabPanel value='7'>
                                    <Box sx={{ width: '100%' }}>
                                        <OverallResult candidateId={candidateDetails.id} eventId={eventID} eventModule={eventModule} username={user.username} isComment={() => setIsComment(true)} />
                                    </Box>
                                </TabPanel>
                                <Box width={"100%"} sx={{ p: 3, textAlign: "left" }}>
                                    <Box
                                        sx={{
                                            mt: 2,
                                            mr: -3,
                                            ml: -3,
                                            mb: 2,
                                            textAlign: "left",
                                            boxShadow: "0px 1px 4px 0px #00000029",
                                        }}
                                    >
                                        <Box sx={{ borderBottom: "2px solid #EDEDF6" }}>
                                            <Box padding={2}>
                                                <strong>Comments</strong>
                                            </Box>
                                        </Box>
                                        <Box padding={2} sx={{ height: "220px", overflow: "hidden", overflowY: "scroll", }}>
                                            { eventStatus !== "Completed" && (
                                                <Button variant='text' sx={{ color: "#067E41" }} onClick={handleComment}>
                                                    <AddCircleIcon /> Add Comment
                                                </Button>
                                            )}
                                            <DisplayComment comments={initialComments} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </TabContext>
                    </Box>
                </Box>
            </Container>:
            <Container disableGutters maxWidth="100%" component="main" sx={{alignItems: "center", justifyContent: 'center',  pl: `calc(${theme.spacing(7)} + 5px)`}}>
                <Box p={2}>
                    <Typography variant='h6'>Finding Assessment Details</Typography> <CircularProgress/>
                </Box>
            </Container>}
            <AddComment candidateId={candidateDetails.id} eventId={eventID} isDialogOpen={isCommentDialogOpen} handleCloseDialog={() => setIsCommentDialogOpen(false)} username={user.username} isComment={() => setIsComment(true)} />
            <Footer />
        </div>
    )
}

export default AssessmentPage