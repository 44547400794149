import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import MenuTab from './menu/MenuTab';
import { Box, Container, Typography, Tooltip, ButtonGroup, useTheme, IconButton, Button, CircularProgress, Stack } from "@mui/material";
// import { GridPagination } from '@mui/x-data-grid';
import RenderCellExpand from '../utils/RenderCellExpand'
import Footer from "./menu/Footer";
import {getCandidateList} from "../lib/db_candidate";
import CandidateToolbarFilter from "./candidate/CandidateToolbarFilter";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import BlacklistCandidate from "./candidate/BlacklistCandidate";
import ViewCandidate from "./candidate/ViewCandidate";
// import { getAge, isCoolingPeriod } from "../utils/isDate";
import { INITIAL_FILTER } from "../utils/candidateFilterUtil";
import { authenticateUser } from "../lib/userAuthenticate";
import { DataGrid } from "@mui/x-data-grid";
import apiService from "../lib/apiService";

function CandidatePage() {
    const navigate = useNavigate()
    const location = useLocation()
    const [user, setUser] = useState({
        username: "",
        url: ""
    })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        authenticateUser(setUser, location, navigate)
    }, [location, navigate])

    const [isBlacklistDialogOpen, setIsBlacklistDialogOpen] = useState(false)
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false)
    // const [rowsCandidates, setRowsCandidate] = useState([])
    // const [allCandidates, setAllCandidates] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [allJrNumber, setAllJrNumber] = useState([])
    const [filterHolder, setFilterHolder] = useState(INITIAL_FILTER)

    const [candidateList, setCandidateList] = useState([]);

    const [hasNext, setHasNext] = useState(false);
    // const [lastId, setLastId] = useState(undefined);

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });

    const [candidateCount, setCandidateCount] = useState(0);

    const initCandidateTotalCount = ( filter ) => {
        apiService.getCandidatesTotalCount({
            filter
        }).then((result) => {
            const count = result.data?.count ?? 0;
            setCandidateCount(count);
        })
    }

    const initializeCandidateList = async ( limit, startAt, filter ) => {
        const result = await getCandidateList( limit, startAt, filter);

        const candidates = result?.candidates ?? [];
        const hn = result?.hasNext ?? false;

        const newCandidates = candidates.filter((c) => !candidateList.some( o => o.id === c.id ) );

        setHasNext( hn );

        if ( startAt ) {
            setCandidateList([
                ...candidateList,
                ...newCandidates
            ]);
        } else {
            setCandidateList(candidates);
        }
    }

    
    const handlePaginationModelChange = async ( pagination ) => {
        const pSize = pagination.pageSize;
        const p = pagination.page;

        const lastPage = Math.ceil(candidateList.length / pSize) - 1;

        console.log(paginationModel.pageSize, pSize);
        
        if ( paginationModel.pageSize !== pSize ) {
            await initializeCandidateList( pSize, undefined, {} );
        } else {
            if ( p > paginationModel.page ) {
                // next
                if ( hasNext && p === lastPage ) {
                    const lastId = candidateList[candidateList.length-1].id;
        
                    await initializeCandidateList( pSize, lastId, filterHolder )
                }
            } else if ( p < paginationModel.page ) {
                // prev
            } else {
                await initializeCandidateList( pSize, undefined, {} );
            }
        }
        
        setPaginationModel(pagination);
    }

    useEffect(() => {
        const getAllData = async () => {
            setIsLoading(true)
            // await getCandidate()
            await initializeCandidateList();
            await getListJrNumber();
            initCandidateTotalCount();
            setIsLoading(false)
        }
        getAllData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getListJrNumber  = async () => {
        apiService.getJrNumbers().then((result) => {
            const jrNumbers = result.data?.jr_numbers ?? [];

            setAllJrNumber(jrNumbers);
        })
    }

    // async function getCandidate() {
    //     const listCandidates = await getAllCandidate()
    //     for (let index = 0; index < listCandidates.length; index++) {
    //         listCandidates[index].exception_status = "N/A"

    //         if(listCandidates[index].is_blacklist === true){
    //             listCandidates[index].exception_status = "Blacklisted"
    //             listCandidates[index].overall_register_status = "Exception - Blacklisted"
    //             continue
    //         }
            
    //         if (listCandidates[index].cooling_period_expiry){
    //             const isException = isCoolingPeriod(listCandidates[index].cooling_period_expiry)
    //             if(isException === true){
    //                 listCandidates[index].exception_status = "Under Cooling Period"
    //                 listCandidates[index].overall_register_status = "Exception - Cooling Period"
    //                 continue
    //             }
    //         } 

    //         if ( listCandidates[index]?.is_kiv === true ) {
    //             listCandidates[index].exception_status = "KIV";
    //             listCandidates[index].overall_register_status = "Exception - KIV"
    //         }

    //         if( listCandidates[index].dob ){
    //             const age = getAge(listCandidates[index].dob)
    //             if(age < 18){
    //                 listCandidates[index].exception_status = "Under Age"
    //                 listCandidates[index].overall_register_status = "Exception - Under Age"
    //             }
    //         }

    //         if ( listCandidates[index].exception_status === "N/A" ) {
    //             listCandidates[index].overall_register_status = listCandidates[index].registration_status ?? "Pending"
    //         }
    //     }
    //     setAllCandidates(listCandidates)
    //     setRowsCandidate(listCandidates)
    // }

    function OpenFirstName(props) {
        const handleClose = (e) => {
            setIsViewDialogOpen(!isViewDialogOpen)
        }
        return (
            <Button disableRipple variant="text" onClick={handleClose} sx={{color: "black", textDecoration: "none", display: "block", textAlign: "left", whiteSpace: 'normal'}}>
                <Typography fontSize={'0.875rem'} lineHeight={'1rem'} color={'black'} fontWeight={500} maxWidth={'100%'}>{props.row.first_name}</Typography>
            </Button>
        )
    }
    function OpenLastName(props) {
        const handleClose = (e) => {
            setIsViewDialogOpen(!isViewDialogOpen)
        }
        return (
            <Button disableRipple variant="text" onClick={handleClose} sx={{color: "black", textDecoration: "none", display: "block", textAlign: "left", whiteSpace: 'normal'}}>
                <Typography fontSize={'0.875rem'} lineHeight={'1rem'} color={'black'} fontWeight={500} maxWidth={'100%'}>{props.row.last_name}</Typography>
            </Button>
        )
    }

    function ActionMenuCandidate(props) {
        const handleClose = (e) => {
            if (e.target.id === 'viewCandidate') {
                setIsViewDialogOpen(!isViewDialogOpen)
            } else if (e.target.id === 'blacklistCandidate'){
                setIsBlacklistDialogOpen(!isBlacklistDialogOpen)
            }
        };
        return(
            <Box sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center"
            }}>
                <ButtonGroup variant='text' size="small">
                    <Tooltip title="View Candidate">
                        <IconButton id='viewCandidate' name='addCandidatesMenu' onClick={handleClose}>
                            <PersonSearchIcon id='addCandidatesMenu' name='addCandidatesMenu' sx={{
                                pointerEvents: 'none',
                                color: 'black'
                            }} />
                        </IconButton>
                    </Tooltip>
                    { props.row.is_blacklist === false && <Tooltip title="Blacklist Candidate">
                        <IconButton id='blacklistCandidate' name='blacklistCandidate' onClick={handleClose}>
                            <PersonOffIcon id='blacklistCandidate' name='blacklistCandidate' sx={{
                                pointerEvents: 'none',
                                color: 'black'
                            }}/>
                        </IconButton>
                    </Tooltip>}
                </ButtonGroup>
            </Box>
        )
    }

    const handleSelectionChange = (selection) => {
        if(selection.length === 0){
            setSelectedRows([])
            return
        }
        const index = candidateList.findIndex(object => {
            return object.id === selection[0]
        })
        setSelectedRows(candidateList[index])
    }

    function CustomToolbar() {
        const [filter, setFilter] = useState(INITIAL_FILTER)
        useEffect(()=>{
            setFilter(filterHolder)
        },[])
        const handleChange = (e, val) => {
            setFilter({
                ...filter,
                [e.target.name]: e.target.value
            })
        }

        const handleFilter = async (e, val) => {
            e.preventDefault()

            if(e.target.id === "applyFilter"){
                setFilterHolder(filter)
            } else if(e.target.id === "clearFilter"){
                setFilterHolder(INITIAL_FILTER)
            }

            await initializeCandidateList(
                paginationModel.pageSize,
                undefined,
                e.target.id === "clearFilter"
                    ? INITIAL_FILTER
                    : filter
            );

            initCandidateTotalCount(
                e.target.id === "clearFilter"
                    ? INITIAL_FILTER
                    : filter
            );
        }
        return (
            <CandidateToolbarFilter handleChange={handleChange} allJrNumber={allJrNumber} handleFilter={handleFilter} holder={filterHolder}/>
        )
    }

    const columnsCandidate = [
        { field: 'first_name',headerClassName: 'theme--header', headerName: 'First Name', minWidth: 150,flex:2, filterable: false, renderCell: OpenFirstName, headerAlign: 'center' },
        { field: 'last_name',headerClassName: 'theme--header', headerName: 'Last Name', minWidth: 150,flex:2, headerAlign: 'center', renderCell: OpenLastName },
        { field: 'registration_status',headerClassName: 'theme--header', headerName: 'Registration Status', width: 150 },
        { field: 'application_date',headerClassName: 'theme--header', headerName: 'Application Date', width: 150},
        { field: 'no_of_events',headerClassName: 'theme--header', headerName: '# of events', width: 100 },
        { field: 'gender',headerClassName: 'theme--header', headerName: 'Gender',renderCell: RenderCellExpand ,width: 80 },
        { field: 'city',headerClassName: 'theme--header', headerName: 'City', width: 150, filterable: false, renderCell: RenderCellExpand },
        { field: 'country',headerClassName: 'theme--header', headerName: 'Country', width: 150, filterable: false },
        { field: 'source',headerClassName: 'theme--header', headerName: 'Source', width: 100, filterable: false },
        { field: 'action',headerClassName: 'theme--header', headerName: 'Action', minWidth: 100, filterable: false,flex:1, renderCell: ActionMenuCandidate, headerAlign: 'center',disableClickEventBubbling: true, },
    ]

    const theme = useTheme();
    return (
        <div style={{ minHeight: "100vh" }}>
            <MenuTab username={user.username} url={user.url} />
            <Container disableGutters maxWidth="100%" component="main" sx={{ pl: `calc(${theme.spacing(7)} + 5px)`, marginBottom: "56px" }}>
                <Box p={2} mt={1}>
                    <Box marginBottom="20px">
                        <Typography variant="h5" color="inherit" noWrap>Candidate List</Typography>
                    </Box>
                    <Box sx={{
                        height: "auto", width: "100%",
                        '& .theme--header': {
                            backgroundColor: '#F1F1F1',
                            opacity: 0.5,
                        },
                    }}>
                        <div style={{ flexGrow: 1 }}>
                                <DataGrid
                                    initialState={{
                                        pagination: { paginationModel: { pageSize: 25 } }
                                    }}
                                    sx={{
                                        "& .MuiDataGrid-columnHeader": {
                                            height: "unset !important"
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            maxHeight: "168px !important"
                                        },
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                        },
                                        "& .MuiDataGrid-cell": {
                                            border: 1,
                                            borderColor: "#D9DBE0"
                                            },
                                        p: 3
                                    }}
                                    rows={candidateList}
                                    count={candidateList.length}
                                    rowCount={candidateList.length}
                                    columns={columnsCandidate}
                                    page={paginationModel.page}
                                    pageSizeOptions={[25,50,100]}
                                    pageSize={paginationModel.pageSize}
                                    autoHeight={true}
                                    pagination
                                    onRowSelectionModelChange={handleSelectionChange}
                                    components={{ 
                                        toolbar: CustomToolbar,
                                        NoRowsOverlay: () => (
                                            <>
                                                <Stack height="100%" alignItems="center" justifyContent="center">
                                                    {isLoading ? <><p>Getting Candidates</p><CircularProgress/></> : "No candidates"}
                                                </Stack>
                                            </>
                                        ),
                                        
                                    }}
                                    componentsProps={{
                                        panel: {
                                            placement: "bottom-end",
                                        },
                                        pagination: {
                                            labelRowsPerPage: 'Rows per page:', // Customize the label as needed
                                            labelDisplayedRows: ({ from, to, count }) => {
                                                if ( count > 0 ) {
                                                    const totalCount = candidateCount > 0
                                                        ? `${candidateCount}`
                                                        : `${count-1}+`

                                                    return `${from}-${to} of ${totalCount}`;
                                                } else {
                                                    return ``;
                                                }
                                            }
                                        },
                                    }}
                                    getRowClassName={(params) => {
                                        return typeof params.row !== "object" ? "": "theme--rows"
                                    }}
                                    onPaginationModelChange={handlePaginationModelChange}
                                />
                        </div>
                    </Box>
                </Box>
            </Container>
            <ViewCandidate isDialogOpen={isViewDialogOpen} handleCloseDialog={()=>setIsViewDialogOpen(false)} id={selectedRows.id}/>
            <BlacklistCandidate isDialogOpen={isBlacklistDialogOpen} handleCloseDialog={() => setIsBlacklistDialogOpen(false)} candidate={selectedRows} isUplift={false} />
            <Footer />
        </div>
    );
}

export default CandidatePage;
