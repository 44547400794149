import React from 'react'
import { storage } from '../lib/firebase'
import { useState } from "react"
import {
    ref,
    uploadBytesResumable,
    getDownloadURL 
} from "firebase/storage"

function TestPage2(){
    const [file, setFile] = useState("");

    const [percent, setPercent] = useState(0);
    
    function handleChange(event) {
        setFile(event.target.files[0]);
    }
    
    function handleUpload() {
        if (!file) {
            alert("Please choose a file first!")
        }
        else{
            console.log(file)
            const storageRef = ref(storage,`/files/${file.name}`)
            console.log(storageRef)
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
         
                    // update progress
                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        console.log(url);
                    });
                }
            );
        }
    }
    return (
        <div>
            <input type="file" accept="image/*" onChange={handleChange}/>
            <button onClick={handleUpload}>Upload to Firebase</button>
            <p>{percent} "% done"</p>
        </div>
    )
}

export default TestPage2