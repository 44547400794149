import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { getGrandFailureRate } from "../../../lib/db_event";
import { DataGrid } from "@mui/x-data-grid";

const numberStyle = {
  padding: "0px 8px",
  alignItems: "center",
  boxShadow: "0px 1px 4px 0px #00000029",
  width: "100%",
  border: 1,
  borderRadius: "8px",
};

export default function AssessmentData({ eventId }) {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const res = await getGrandFailureRate(eventId);
      res.forEach((value, index) => {
        value.id = index;
      });
      setRows(res);
    };
    getData();
  }, [eventId]);

  const renderCellNumberReject = (props) => {
    let number = Math.round(props.value * 100) / 100 + "%";
    if (props.value === 0) number = "-";
    return (
      <>
        <Typography
          textAlign={"center"}
          color={"#B4342A"}
          style={{ ...numberStyle, backgroundColor: "#FBEBE9" }}
        >
          {number}
        </Typography>
      </>
    );
  };
  const renderCellNumberPassed = (props) => {
    let number = Math.round(props.value * 100) / 100 + "%";
    if (props.value === 0) number = "-";
    return (
      <>
        <Typography
          textAlign={"center"}
          color={"#1A5436"}
          style={{ ...numberStyle, backgroundColor: "#ECFBF3" }}
        >
          {number}
        </Typography>
      </>
    );
  };

  const columnsCandidate = [
    {
      field: "assessment",
      headerClassName: "theme--header",
      headerName: "Type of Assessment",
      minWidth: 100,
      flex: 1,
      filterable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assessed",
      headerClassName: "theme--header",
      headerName: "No. of candidates",
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rejected",
      flex: 1,
      headerClassName: "theme--header",
      headerName: "No. of rejection",
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "rejected_rate",
      headerClassName: "theme--header",
      headerName: "Rejection percentage",
      minWidth: 100,
      filterable: false,
      flex: 1,
      headerAlign: "center",
      renderCell: renderCellNumberReject,
      align: "center",
    },
    {
      field: "passed",
      headerClassName: "theme--header",
      headerName: "No. of passed",
      minWidth: 100,
      filterable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "passed_rate",
      headerClassName: "theme--header",
      headerName: "Passed percentage",
      minWidth: 100,
      filterable: false,
      flex: 1,
      headerAlign: "center",
      renderCell: renderCellNumberPassed,
      align: "center",
    },
  ];
  return (
    <>
      <Box
        sx={{
          height: "auto",
          width: "100%",
          "& .theme--header": {
            backgroundColor: "white",
            marginBottom: "8px",
            marginTop: "8px"
          },
          "& .theme--rows": {
            backgroundColor: "white",
          },
        }}
      >
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-columnHeader": {
                // Forced to use important since overriding inline styles
                height: "unset !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                // Forced to use important since overriding inline styles
                maxHeight: "168px !important",
              },
              ".MuiDataGrid-columnSeparator": {
                display: "none",
              },
              "& .MuiDataGrid-cell": {
                backgroundColor: "white",
              },
              border: 0,
            }}
            rows={rows}
            columns={columnsCandidate}
            autoHeight={true}
            componentsProps={{
              panel: {
                placement: "bottom-end",
              },
            }}
            getRowClassName={(params) => {
              return typeof params.row !== "object" ? "" : "theme--rows";
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[10, 25, 50]}
            disableColumnMenu
          />
        </div>
      </Box>
    </>
  );
}
