import { Box, Button, Popper, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { TabContext, TabPanel } from "@mui/lab";
import BlacklistKIV from "./BlacklistKIV";
import GenderAge from "./GenderAge";
import AssessmentData from "./AssessmentData";
import { exportAllToCSV, getDashboardEventData } from "../../../lib/db_event";
import HelpIcon from '@mui/icons-material/Help';

const percentageStyle = {
  padding: "0px 8px",
  alignItems: "center",
  boxShadow: "0px 1px 4px 0px #00000029",
  width: "fit-content",
  backgroundColor: "white",
  border: 1,
  borderRadius: "8px",
};

export default function EventDashboardMainInformation({ eventId }) {
  const [tabIndex, setTabIndex] = useState("1");
  const [successful, setSuccesful] = useState({
    success: 0,
    rates: 0,
  });
  const [failed, setFailed] = useState({
    fail: 0,
    rates: 0,
  });
  const [kiv, setKiv] = useState({
    kiv: 0,
    rates: 0,
  });
  const [inProgress, setInProgress] = useState({
    inProgress: 0,
    rates: 0,
  });
  const [showPopper, setShowPopper] = useState(false)
  const [showPopperIP, setShowPopperIP] = useState(false)
  const [anchorElKIV, setAnchorElKIV] = useState(null)
  const [anchorElInProgress, setAnchorElInProgress] = useState(null)

  const handleChangeEditTabs = (e, activeTab) => {
    setTabIndex(activeTab);
  };

  useEffect(() => {
    const getGeneralInfo = async () => {
      const res = await getDashboardEventData(eventId)
      setInProgress({
        inProgress: res.metrics.totalInProgress,
        rates: Math.round(res.inProgressRates * 100) / 100 + "%",
      })
      setFailed({
        fail: res.metrics.totalFailed,
        rates: Math.round(res.failedRates * 100) / 100 + "%",
      });
      setKiv({
        kiv: res.metrics.totalKIV,
        rates: Math.round(res.kivRates * 100) / 100 + "%",
      });
      setSuccesful({
        success: res.metrics.totalPassed,
        rates: Math.round(res.passedRates * 100) / 100 + "%",
      });
    };
    getGeneralInfo();
  }, [eventId]);

  const exportAsCSV = async () => {
    await exportAllToCSV(eventId)
  }

  const handleMouseEnter = (e) => {
    setAnchorElKIV(e.currentTarget);
    setShowPopper(true)
  }

  const handleMouseLeave = () => {
    setShowPopper(false)
  }

  const handleMouseEnterIP = (e) => {
    setAnchorElInProgress(e.currentTarget);
    setShowPopperIP(true)
  }

  const handleMouseLeaveIP = () => {
    setShowPopperIP(false)
  }

  return (
    <>
      <Box
        display={"flex"}
        textAlign={"center"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          width: "100%",
          p: 1,
          boxShadow: "0px 4px 4px 0px #00000014",
          backgroundColor: "#FFFFFF",
          minHeight: "50px",
          position: "relative",
        }}
      >
        <Typography color={"#3A3D7D"}>
          <strong>Results Data</strong>
        </Typography>
        <Button onClick={exportAsCSV} color="csvButton" variant="outlined" size="small" sx={{ mr: 2 }}>
          <UploadIcon />
          Download as CSV.
        </Button>
      </Box>
      <Box display={"flex"} gap={"25px"} margin={"24px 48px 24px 24px"}>
        <Box
          flex={1.5}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            boxShadow: "0px 1px 4px 0px #00000029",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <Box 
            flex={1} 
            sx={{
              backgroundImage: "url(/images/inProgressVector.png)",
              backgroundRepeat: "no-repeat", 
              backgroundSize: "65px",
              backgroundPosition: "left",
              backgroundPositionY: "-1px",
              backgroundPositionX: "-1px"
            }}
          />
          <Box flex={2} padding={1}>
            <Box display={"flex"}>
              <Typography variant="body2" color={"#4B4FA6"}>
                Total results In-progress
              </Typography>
              <Box onMouseEnter={handleMouseEnterIP} onMouseLeave={handleMouseLeaveIP}>
                <HelpIcon sx={{ height: "16px", width: "16px", color: '#3A3D7D', ml: "16px" }} />
                <Popper anchorEl={anchorElInProgress} open={showPopperIP} placement='right'>
                  <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', maxWidth: "30ch" }}>
                    <Typography variant='caption'>Candidates who are still under assessment.</Typography>
                  </Box>
                </Popper>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"#4B4FA6"} variant="body1">
                {inProgress.inProgress}
              </Typography>
              <Typography color={"white"} style={{ ...percentageStyle, backgroundColor: "#4B4FA6" }}>
                {inProgress.rates}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          padding={1}
          flex={1}
          sx={{
            backgroundColor: "#1A5436",
            borderRadius: "4px",
            boxShadow: "0px 1px 4px 0px #00000029",
          }}
        >
          <Typography variant="body2" color={"white"}>
            Successful Candidates
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography color={"white"} variant="body1">
              {successful.success}
            </Typography>
            <Typography color={"#1A5436"} style={percentageStyle}>
              {successful.rates}
            </Typography>
          </Box>
        </Box>
        <Box
          padding={1}
          flex={1}
          sx={{
            backgroundColor: "#B4342A",
            boxShadow: "0px 1px 4px 0px #00000029",
            borderRadius: "4px",
          }}
        >
          <Typography variant="body2" color={"white"}>
            Failed Candidates
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography color={"white"} variant="body1">
              {failed.fail}
            </Typography>
            <Typography color={"#B4342A"} style={percentageStyle}>
              {failed.rates}
            </Typography>
          </Box>
        </Box>
        <Box
          padding={1}
          flex={1}
          sx={{
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 1px 4px 0px #00000029",
            borderRadius: "4px",
          }}
        >
          <Box display={"flex"}>
            <Typography variant="body2">KIV</Typography>
            <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <HelpIcon sx={{ height: "16px", width: "16px", color: '#3A3D7D', ml: "16px" }} />
              <Popper anchorEl={anchorElKIV} open={showPopper} placement='left'>
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper', maxWidth: "30ch" }}>
                  <Typography variant='caption'>Candidates whose final application results are pending due to various considerations.</Typography>
                </Box>
              </Popper>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography color={"black"} variant="body1">
              {kiv.kiv}
            </Typography>
            <Typography
              color={"white"}
              style={{ ...percentageStyle, backgroundColor: "black" }}
            >
              {kiv.rates}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box marginLeft={"24px"}>
        <Typography variant="body1" color={"#3A3D7D"}>
          <strong>Breakdown Analysis</strong>
        </Typography>
        <TabContext value={tabIndex}>
          <Box
            marginTop={1}
            sx={{
              backgroundColor: "#FFFFFF",
              width: "fit-content",
              boxShadow: "0px 1px 4px 0px #00000029",
            }}
          >
            <Tabs
              orientation="horizontal"
              value={tabIndex}
              onChange={handleChangeEditTabs}
            >
              <Tab
                disableRipple
                label={"Assessment Data"}
                value="1"
                sx={{ border: "4px" }}
              />
              <Tab disableRipple label={"Blacklist"} value="2" />
              <Tab disableRipple label={"KIV"} value="3" />
              <Tab disableRipple label={"Gender breakdown"} value="4" />
              <Tab disableRipple label={"Age Data"} value="5" />
            </Tabs>
          </Box>
          <Box m={"-24px"} sx={{ width: "100%" }}>
            <TabPanel value="1">
              <AssessmentData eventId={eventId} />
            </TabPanel>
            <TabPanel value="2">
              <BlacklistKIV eventId={eventId} tab={"Blacklist"} />
            </TabPanel>
            <TabPanel value="3">
              <BlacklistKIV eventId={eventId} tab={"KIV"} />
            </TabPanel>
            <TabPanel value="4">
              <GenderAge eventId={eventId} tab={"Gender"} />
            </TabPanel>
            <TabPanel value="5">
              <GenderAge eventId={eventId} tab={"Age"} />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </>
  );
}
