import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// import { addEventToCandidate, addJobApplicationToCandidate, updateCandidateBlacklistStatus, updateCandidateCoolingPeriod, updateCandidateRegistrationStatus } from "../../lib/db_candidate";
// import { addCandidateToEvent, assignCandidateNumberToEventCandidates } from "../../lib/db_event";
// import { autoCreateCandidateJobApplication, triggerAutoSendInvitationEmail } from "../../lib/page";
import apiService from "../../lib/apiService";
import {triggerAutoSendInvitationEmail } from "../../lib/page";
import Loading from "../shared/Loading";
import { decryptData } from "../../utils/encryption";

const AddToEventModal = ( props ) => {
  const { open, eventId, candidates, handleCloseModal, onSuccess, onError } = props;

  const [countCP, setCountCP] = useState(0);
  const [countBL, setCountBL] = useState(0);
  const [countKIV, setCountKIV] = useState(0);
  const [countUA, setCountUA] = useState(0);
  const [saving, setIsSaving] = useState(false);

  const paperProps = {
    style: {
      maxWidth: 440,
      margin: '0 auto',
      width: '100%',
    },
  };

  /**
   * Checks if has exceptions
   * @returns 
   */
  const hasCount = () => {
    return countCP > 0 || countBL > 0 || countKIV > 0 || countUA > 0;
  }

  /**
   * Calculates age using birth day
   * @param {*} birthdate 
   * @returns 
   */
  const calculateAge = async (birthdate) => {
    if ( birthdate ) {
      birthdate = await decryptData(birthdate)
      console.log("b >", birthdate);
      const birthDateObj = new Date(birthdate);
      const now = new Date();
    
      const birthYear = birthDateObj.getFullYear();
      const birthMonth = birthDateObj.getMonth();
      const birthDay = birthDateObj.getDate();
    
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();
      const currentDay = now.getDate();
    
      let age = currentYear - birthYear;
    
      // Check if the birthday has occurred this year
      if (
        currentMonth < birthMonth ||
        (currentMonth === birthMonth && currentDay < birthDay)
      ) {
        age--;
      }
    
      return age;
    } else {
      return 0;
    }
  }

  /**
   * Checks if candidate is in cooling period
   * @param {*} expiry 
   * @returns 
   */
  const isInCoolingPeriod = (expiry) => {
    if ( expiry ) {
      const today = new Date();
      const expiryDate = new Date(expiry);

      return today < expiryDate ? true : false;
    } else {
      return false;
    }
  }

  const candidatesWithoutExceptions = async (candidates) => {
    if (!Array.isArray(candidates)) {
      console.error('candidates is not an array.');
      return [];
    }

    const filteredCandidates = await Promise.all(
      candidates.map(async (candidate) => {
        const inCoolingPeriod = isInCoolingPeriod(candidate.cooling_period_expiry);
        const blacklisted = candidate.is_blacklist ? candidate.is_blacklist : false;
        const kiv = candidate.is_kiv ? candidate.is_kiv : false;
        const underAge = await calculateAge(candidate.dob) < 18;
    
        return {
          candidate,
          isValid: !inCoolingPeriod && !blacklisted && !kiv && !underAge
        };
      })
    ).then(results => results.filter(result => result.isValid).map(result => result.candidate));
  
    return filteredCandidates;
  };

  const handleAddAll = async () => {
    setIsSaving(true);
    try {
      // TODO: add to event even if not qualified
      // unblacklist if blacklisted
      // for( const candidate of candidates ) {
      //   const candidateId = candidate.id;

      //   await addEventToCandidate(eventId, candidateId);

      //   await updateCandidateBlacklistStatus(candidateId, false);
      //   await updateCandidateCoolingPeriod(candidateId, "");
      //   await updateCandidateRegistrationStatus(candidateId, "Pending");
      //   await addJobApplicationToCandidate(eventId, candidate);
      //   await addCandidateToEvent(candidateId, eventId);
      // }

      // await assignCandidateNumberToEventCandidates(eventId);
      // await autoCreateCandidateJobApplication(eventId);
      // await triggerAutoSendInvitationEmail(eventId)

      const candidateIds = candidates.map((c) => c.id);
      const candidateIdsString = candidateIds.join(",");

      await apiService.addCandidatesToEvent({ 
        eventId: eventId,
        candidateIds: candidateIdsString 
      });

      apiService.autoUpdateJobApplication({
        eventId: eventId,
        candidateIds: candidateIdsString
      });

      await triggerAutoSendInvitationEmail(eventId)
    } catch(e) {
      onError(e);
      handleCloseModal();
    }
    setIsSaving(false);
    onSuccess();
  }

  const handleAddQualifiedOnly = async () => {
    // TODO: add qualifed only to event
    setIsSaving(true);
    try {
      const filteredCandidates = candidatesWithoutExceptions(candidates);
    
      // for( const candidate of filteredCandidates ) {
      //   const candidateId = candidate.id;

      //   await addEventToCandidate(eventId, candidateId);
      //   // update status
      //   await updateCandidateRegistrationStatus(candidateId, "Pending");

      //   await addJobApplicationToCandidate(eventId, candidate);
      //   await addCandidateToEvent(candidateId, eventId);
      // }

      // await assignCandidateNumberToEventCandidates(eventId);
      // await autoCreateCandidateJobApplication(eventId);
      // await triggerAutoSendInvitationEmail(eventId)

      const candidateIds = filteredCandidates.map((c) => c.id);
      const candidateIdsString = candidateIds.join(",");

      await apiService.addCandidatesToEvent({ 
        eventId: eventId,
        candidateIds: candidateIdsString 
      });

      apiService.autoUpdateJobApplication({
        eventId: eventId,
        candidateIds: candidateIdsString
      });

      await triggerAutoSendInvitationEmail(eventId)
    } catch(e) {
      onError(e);
      handleCloseModal();
      return;
    }
    setIsSaving(false)
    onSuccess();
  }

  useEffect(() => {
    const setCounts = async () => {
      const cp = candidates.filter(e => isInCoolingPeriod(e.cooling_period_expiry) ).length;
      const bl = candidates.filter(e => e.is_blacklist ? e.is_blacklist : false).length;
      const kiv = candidates.filter(e => e.is_kiv ? e.is_kiv : false ).length;
      const uaCandidates = await Promise.all(
        candidates.map(async (e) => {
          const underAge = await calculateAge(e.dob) < 18;
          return underAge ? e : null;
        })
      );
  
      const ua = uaCandidates.filter(e => e !== null).length;

      setCountCP(cp);
      setCountBL(bl);
      setCountKIV(kiv);
      setCountUA(ua);
    }
    
    setCounts();
    return () => {}
  }, [candidates])

  return(
    <Dialog
      open={open}
      onClose={handleCloseModal}
      PaperProps={paperProps}
      
      fullWidth>
      <DialogTitle>
        <Box>
          <Box textAlign="right">
            <IconButton onClick={handleCloseModal} >
              <Close />
            </IconButton>
          </Box>
          <Typography variant="h5" fontSize="16px" fontWeight="medium" color="#000" mb={-2}>Disqualified Candidates (Blacklisted, within cooling period, KIV statuses or under age):</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          { hasCount() ? (
            <ul style={{ color: "#75767A", fontSize: "16px" }}>
              { countCP > 0 && ( <li>Within Cooling Period - {countCP}</li> )}
              { countBL > 0 && ( <li>Blacklisted - {countBL}</li>)}
              { countKIV > 0 && ( <li>KIV - {countKIV}</li>)}
              { countUA > 0 && ( <li>Under Age - {countUA}</li>)}
            </ul>
          ) : (
            <ul style={{ color: "#75767A", fontSize: "16px" }}>
              <li>NA</li>
            </ul>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" gap={2} width="100%" p={2} pt={0}>
            <Button disabled={saving} onClick={handleAddAll} sx={{ height: "48px", fontSize: "16px" }} variant="outlined" color="primary" fullWidth>
              Add All
            </Button>
            <Button disabled={saving} onClick={handleAddQualifiedOnly} sx={{ height: "48px", fontSize: "16px" }} variant="contained" color="primary" fullWidth>
              Add Qualified Only
            </Button>
        </Box>
      </DialogActions>
      <Loading isLoading={saving} handleStopLoading={() => setIsSaving(false)} state={false} />
    </Dialog>
  )
}

export default AddToEventModal;