import { db } from "./firebase";
import { getAuth } from "firebase/auth";
import { 
    doc, 
    getDoc, 
    setDoc, 
    updateDoc, 
    deleteDoc, 
    collection,
    query, 
    where, 
    collectionGroup,
    getDocs, 
    serverTimestamp,
    arrayUnion,
    endBefore,
    startAfter,
    orderBy,
    limit,
} from "firebase/firestore";
import { objectToCSV } from "../utils/objectsToCSV";
import { saveAs } from 'file-saver';
import { dateToYYYYMMDD, getTotalYearsYYYYMM } from "../utils/date";
import { getAge } from "../utils/isDate";
import { getJrNumberDetails } from "./db_event";
import apiService from "./apiService";
import { getCandidateEventAttendedId } from "./page";
import { decryptData, encryptData } from "../utils/encryption";

/**
 * Removes undefined values, since undefined values will return error upon saving it to firestore
 * @param {*} obj 
 * @returns 
 */
const preReadyData = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map((item) => preReadyData(item));
    }

    return Object.keys(obj).reduce((result, key) => {
        const value = obj[key];
        result[key] = preReadyData(value);
        return result;
    }, {});
}

const checkIfCandidateExists =  async (candidateID) => {
    const eventDoc = await getDoc( doc( db, "candidates", candidateID ) );

    return eventDoc.exists();
}

const updateRegistrationData = async (candidateID, walkinCandidate) => {
    //console.log(walkinCandidate)
    
    const newCandidate = {}
    // Add default field
    newCandidate['source'] = 'Walk In'
    newCandidate['registration_status'] = 'Confirmed'
    newCandidate['city'] = walkinCandidate.city
    newCandidate['country'] = walkinCandidate.country
    newCandidate['email'] = await encryptData(walkinCandidate.email)
    newCandidate['full_name'] = walkinCandidate.first_name + " " + walkinCandidate.last_name
    newCandidate['first_name'] = walkinCandidate.first_name
    newCandidate['last_name'] = walkinCandidate.last_name
    newCandidate['gender'] = walkinCandidate.gender
    newCandidate['national_id'] = await encryptData(walkinCandidate.national_id)
    newCandidate['nationality'] = walkinCandidate.nationality
    newCandidate['dob'] = await encryptData(walkinCandidate.dob)
    newCandidate['age'] = getAge(walkinCandidate.dob)
    newCandidate['phone_number'] = await encryptData( walkinCandidate.phone_number) ?? ''
    
    //console.log(newCandidate)
    await updateDoc(doc(db, "candidates", candidateID), {
        ...newCandidate,
        updated_at: serverTimestamp()
    });

}

const registerWalkInCandidate = async (walkinCandidate) => {
    //console.log(walkinCandidate)
    
    const newCandidate = {}
    // Add default field

    newCandidate['candidate_id'] = walkinCandidate.candidate_id;
    newCandidate['address'] = []
    newCandidate['availability_start_date'] = ''
    newCandidate['is_blacklist'] = false;
    newCandidate['is_kiv'] = false;
    newCandidate['cooling_period_expiry'] = ''
    newCandidate['assessment_status'] = 'Pending'
    newCandidate['education'] = []
    newCandidate['source'] = 'Walk In'
    newCandidate['vaccination'] = false
    newCandidate['registration_status'] = 'Confirmed'
    newCandidate['candidate_status'] = 'Review'
    newCandidate['city'] = walkinCandidate.city
    newCandidate['country'] = walkinCandidate.country
    newCandidate['application_date'] = walkinCandidate.application_date
    newCandidate['email'] = await encryptData(walkinCandidate.email)
    newCandidate['full_name'] = walkinCandidate.first_name + " " + walkinCandidate.last_name
    newCandidate['first_name'] = walkinCandidate.first_name
    newCandidate['last_name'] = walkinCandidate.last_name
    newCandidate['gender'] = walkinCandidate.gender
    newCandidate['jr_number'] = walkinCandidate.jr_number
    newCandidate['national_id'] = await encryptData(walkinCandidate.national_id)
    newCandidate['nationality'] = walkinCandidate.nationality
    newCandidate['passport'] = await encryptData(walkinCandidate.national_id)
    newCandidate['dob'] = await encryptData(walkinCandidate.dob)
    newCandidate['age'] = getAge(walkinCandidate.dob)
    newCandidate['phone_number'] = await encryptData( walkinCandidate.phone_number) ?? ''
    
    // Check for age, update registration status for under age
    if(getAge(walkinCandidate.dob) < 18){
        newCandidate['registration_status'] = 'Exception - Under Age'
    }

    await setDoc(doc(db, "candidates", newCandidate['candidate_id'] ), {
        ...newCandidate,
        created_at: serverTimestamp()
    });
    const jobApplication = {}
    jobApplication['AOC_Country'] = walkinCandidate.aoc_country ?? ''
    jobApplication['Location'] = walkinCandidate.job_location ?? ''
    jobApplication['Candidate_Creation_Date'] = newCandidate['application_date'] ?? '';
    jobApplication['Candidate_ID'] = ''
    jobApplication['Candidate_Stage'] = 'Review'
    jobApplication['City'] = walkinCandidate.city ?? '';
    jobApplication['Company'] = walkinCandidate.job_company ?? ''
    jobApplication['Country'] = walkinCandidate.country ?? '';
    jobApplication['Date_of_Birth'] = await encryptData(walkinCandidate.dob) ?? '';
    jobApplication['Email'] = await encryptData(walkinCandidate.email) ?? '';
    jobApplication['First_Name'] = walkinCandidate.first_name ?? '';
    jobApplication['Gender'] = walkinCandidate.gender ?? '';
    jobApplication['Job_Posting_Title'] = walkinCandidate.job_title ?? ''
    jobApplication['Job_Req_ID'] = walkinCandidate.jr_number[0] ?? '';
    jobApplication['Language_Spoken'] = walkinCandidate.language_spoken ?? []
    // if(walkinCandidate.language_spoken.length > 0){
    //     jobApplication['Language_Spoken'] = walkinCandidate.language_spoken[0].language
    // }
    // else{
    //     jobApplication['Language_Spoken'] = ''
    // }    
    jobApplication['Last_Name'] = walkinCandidate.last_name ?? '';
    jobApplication['LinkedIn_Profile'] = walkinCandidate.linkin_url_profile ?? ""
    jobApplication['National_ID'] = await encryptData(walkinCandidate.national_id) ?? '';
    jobApplication['Nationality'] = walkinCandidate.nationality ?? '';
    jobApplication['Phone_Number'] = await encryptData(walkinCandidate.phone_number) ?? '';
    if(walkinCandidate.work_experience.length > 0){
        jobApplication['work_experiences'] = {...walkinCandidate.work_experience}
    }
    else{
        jobApplication['work_experiences'] = []
    }
    jobApplication['Worked_AA_Before'] = walkinCandidate.have_you_worked_at_airasia_before ?? "NA";
    jobApplication['Job_Source'] = walkinCandidate.how_you_heard_about_this_job ?? "NA";
    jobApplication['is_walkin'] = true;
    const data = preReadyData(jobApplication);
    
    //console.log(jobApplication)
    await addCandidateJobApplication(walkinCandidate.candidate_id, walkinCandidate.jr_number[0], data)
    //await setDoc(doc(db, "candidates", walkinCandidate.national_id, "job_application", walkinCandidate.jr_number[0]), jobApplication)
    
    return newCandidate["registration_status"];
}

const getExceptionStatus = (candidateInfo) => {
    let exceptionStatus = "N/A";

    if(candidateInfo !== undefined){
        //console.log('Candidate ID valid')
        if(candidateInfo['age'] < 18){
            // Check for under age
            exceptionStatus = 'Under Age'
        }
        if(candidateInfo['is_kiv']){
            // Check for KIV
            // Query assessment result for KIV status
            exceptionStatus = 'KIV'
        }
        if(candidateInfo['cooling_period_expiry'] !== ""){
            // Check for cooling period
            const today = new Date()
            const expiryDate = new Date(candidateInfo['cooling_period_expiry'])
            if(today < expiryDate){
                exceptionStatus = 'Under Cooling Period'
            }
        }
        if(candidateInfo['is_blacklist']){
            // Check for blacklist
            exceptionStatus = 'Blacklisted'
        }
    }

    return exceptionStatus
}

const getCandidateWalkInRegistrationStatus = (candidateInfo) => {
    let registrationStatus = "Pending"
    
    if(candidateInfo !== undefined){
        //console.log('Candidate ID valid')
        if(candidateInfo['age'] < 18){
            // Check for under age
            registrationStatus = 'Under Age'
        }else if(candidateInfo['is_kiv']){
            // Check for KIV
            // Query assessment result for KIV status
            registrationStatus = 'KIV'
        } else if(candidateInfo['cooling_period_expiry'] !== ""){
            // Check for cooling period
            const today = new Date()
            const expiryDate = new Date(candidateInfo['cooling_period_expiry'])
            if(today < expiryDate){
                registrationStatus = 'Under Cooling Period'
            }
        } else if(candidateInfo['is_blacklist']){
            // Check for blacklist
            registrationStatus = 'Blacklisted'
        }
        
    }
    return registrationStatus
}

const getCandidateById = async (candidateId) => {
    // Get a document
    const candidate = {};
    const candidateDocRef = doc(db, "candidates", candidateId);
    const candidateDocSnap = await getDoc(candidateDocRef);
    const candidateInfo = candidateDocSnap.data()
    if(candidateInfo === undefined){
        return candidate
    }
    // candidateInfo["age"] = getAge(candidateInfo.dob)
    const dob = await decryptData(candidateInfo.dob);

    candidate['workday_candidate_id'] = candidateInfo?.candidate_id ?? "";
    candidate['is_blacklist'] = candidateInfo.is_blacklist
    candidate['is_kiv'] = candidateInfo.is_kiv
    candidate['cooling_period_expiry'] = candidateInfo.cooling_period_expiry
    candidate['registration_status'] = candidateInfo.registration_status
    candidate['first_name'] = candidateInfo.first_name
    candidate['last_name'] = candidateInfo.last_name
    candidate['phone_number'] = ''
    candidate['email'] = await decryptData(candidateInfo.email)
    candidate['linkin_url_profile'] = ''
    candidate['city'] = candidateInfo.city
    candidate['country'] = candidateInfo.country
    candidate['gender'] = candidateInfo.gender
    candidate['national_id'] = await decryptData(candidateInfo.national_id)
    candidate['nationality'] = candidateInfo.nationality
    candidate['dob'] = dob
    candidate['age'] = getAge(dob)
    candidate['how_you_heard_about_this_job'] = 'NA'
    candidate['have_you_worked_at_airasia_before'] = 'NA'
    candidate['language_spoken'] = []
    candidate['work_experience'] = []
    candidate['source'] = candidateInfo.source
    candidate['assessment_status'] = candidateInfo.assessment_status

    const candidateJobApplicationSnap = await getDocs(collection(db, "candidates", candidateId, "job_application"), orderBy("updated_at", "desc"));

    const lastJobApplication = !candidateJobApplicationSnap.empty ? candidateJobApplicationSnap.docs[0] : undefined;

    let _languages = [];
    let _workedAAs = [];

    if ( !candidateJobApplicationSnap.empty ) {
        for ( const jobApplication of candidateJobApplicationSnap.docs ) {
            const jobApplicationData = jobApplication.data();
            const languageSpoken = jobApplicationData?.Language_Spoken ?? [];
    
            const isArray = Array.isArray(languageSpoken);
    
            if ( isArray ) {
                _languages = [
                    ..._languages,
                    ...languageSpoken
                ];
            } else {
                _languages.push(languageSpoken);
            }
    
            const workedAA = jobApplicationData?.Worked_AA_Before ?? "No";
    
            _workedAAs.push(workedAA);
        }

        const languageMap = {};
        for ( const lang of _languages ) {
            const key = `${lang.language}_${lang.language_ability_type}`;
            if (!languageMap[key]) {
                languageMap[key] = lang;
            }
        }

        _languages = Object.values(languageMap);
    }

    const haveWorkedOnAABefore = _workedAAs.includes("Yes");

    if ( lastJobApplication ) {
        const lastJobApplicationData = lastJobApplication.data();

        // candidate['have_you_worked_at_airasia_before'] = lastJobApplicationData.Worked_AA_Before ?? "No";
        candidate['how_you_heard_about_this_job'] = lastJobApplicationData.Job_Source ?? "NA";

        candidate['candidate_application_date'] = lastJobApplicationData.Candidate_Creation_Date;
        candidate['candidate_status'] = lastJobApplicationData.Candidate_Stage;
        if(!candidate['workday_candidate_id'].includes("CAND-")){
            candidate['workday_candidate_id'] = lastJobApplicationData?.Candidate_ID ?? "";
            // candidate['workday_candidate_id'] = lastJobApplicationData?.Candidate_ID ?? candidate['workday_candidate_id'] ?? "";
        }
        candidate['phone_number'] = await decryptData(lastJobApplicationData.Phone_Number);
        candidate['linkin_url_profile'] = lastJobApplicationData.LinkedIn_Profile;

        // const isArray = Array.isArray(lastJobApplicationData.Language_Spoken);

        // if ( isArray ) {
        //     candidate['language_spoken'] = lastJobApplicationData.Language_Spoken
        // } else {
        //     candidate['language_spoken'].push({
        //         language: lastJobApplicationData.Language_Spoken,
        //         language_ability_type: "Speaking",
        //         language_proficiency: 'Intermediate'
        //     });
        // }
    }

    candidate['have_you_worked_at_airasia_before'] = haveWorkedOnAABefore ? "Yes" : "No"
    candidate['language_spoken'] = _languages;

    let _exps = [];
    for( const jrDoc of candidateJobApplicationSnap.docs ) {
        console.log(jrDoc.data());
        const jrDocData = jrDoc.data();

        let workExperiences = jrDocData?.work_experiences ?? [];

        if (!Array.isArray(workExperiences)) {
            // Ensure workExperiences is always an array
            workExperiences = Object.values(jrDocData?.work_experiences);
        }

        if (!Array.isArray(workExperiences)) {
            // Ensure workExperiences is always an array
            workExperiences = [];
        }

        for( const w of workExperiences ) {
            if ( !_exps.includes(w) ) {
                _exps.push(w);
            }
        }
    }

    candidate['work_experience'] = _exps;
    
    candidate['exception_status'] = getExceptionStatus(candidate)

    return candidate;
}

const checkIfNationalIdExists = async ( nationalId ) => {
    const encID = await encryptData(nationalId);

    if ( encID ) {
        const q = query( collection( db, 'candidates'), where('national_id', '==', encID) );
        const qs = await getDocs(q);

        const hasCandidate = qs.size > 0;

        if ( hasCandidate ) {
            const id =  qs.docs[0].id;
            return id;
        } else {
            return encID;
        }
    } else {
        return encID;
    }
}
const getBlacklistCandidates = async () => {
    // Get multiple documents from a collection
    //const q = query(collection(db, "candidates"), null);
    let blacklistCandidate = [];
    const q = query(collection(db, "candidates"), where("is_blacklist", "==", true));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        blacklistCandidate.push({"id" : doc.id,"data" : doc.data()});
    });
    //console.log("Total candidate returned: " + querySnapshot.size);
    //console.log(blacklistCandidate);
    return blacklistCandidate;
}

const getAddToEventCandidateList = async ( eventId, filter ) => {
    const result = await apiService.getCandidateList({
        eventId,
        filter
    });

    console.log(result);

    return result?.data;
}

const getCandidateList = async ( limit, startAt, filter = {}) => {

    const result = await apiService.getCandidateList({
        limit,
        startAt,
        filter
    });

    return result?.data;
}

const getAllCandidate = async () => {
    let candidates = [];

    try {
        const querySnapshot = await getDocs(collection(db, "candidates"));

        await Promise.all(querySnapshot.docs.map(async (doc) => {
            const candidateData = doc.data();
            const eventCompanyName = []
            if(candidateData.jr_number){
                await Promise.all(candidateData.jr_number.map(async (jrNumber) => {
                    try {
                        let eventData = await getJrNumberDetails(jrNumber)
                        eventCompanyName.push(eventData.aoc_company)
                    } catch (error) {
                        console.error(`error fetching event company name ${candidateData.id}`);
                    }
                }))
            }
            candidateData['event_company_name'] = [...new Set(eventCompanyName)]
            
            try {
                const candidateJobApplicationSnap = await getDocs(collection(db, "candidates", doc.id, "job_application"));

                const lastJobApplication = !candidateJobApplicationSnap.empty ? candidateJobApplicationSnap.docs[candidateJobApplicationSnap.docs.length-1] : undefined;

                if (lastJobApplication) {
                    if(doc.id === "1017207"){
                        console.log(candidateJobApplicationSnap.docs);
                    }
                    const lastJobApplicationData = lastJobApplication.data();

                    candidateData['have_you_worked_at_airasia_before'] = lastJobApplicationData.Worked_AA_Before ?? false;
                    candidateData['how_you_heard_about_this_job'] = lastJobApplicationData.Job_Source ?? "NA";
                    candidateData['candidate_application_date'] = lastJobApplicationData.Candidate_Creation_Date;
                    candidateData['candidate_status'] = lastJobApplicationData.Candidate_Stage;
                    candidateData['workday_candidate_id'] = lastJobApplicationData?.Candidate_ID ?? candidateData?.candidate_id ?? "";
                    candidateData['phone_number'] = lastJobApplicationData.Phone_Number;
                    candidateData['linkin_url_profile'] = lastJobApplicationData.LinkedIn_Profile;

                    const isArray = Array.isArray(lastJobApplicationData.Language_Spoken);

                    if ( isArray ) {
                        candidateData['language_spoken'] = lastJobApplicationData.Language_Spoken
                    } else {
                        candidateData['language_spoken'] = [{
                            language: lastJobApplicationData.Language_Spoken,
                            language_ability_type: "Speaking",
                            language_proficiency: 'Intermediate'
                        }]
                    }
                }

                const experienceArray = [];
                candidateJobApplicationSnap.docs.forEach((jrDoc)=>{
                    const jrDocData = jrDoc.data();

                    let workExperiences = jrDocData?.work_experiences ?? [];

                    if (!Array.isArray(workExperiences)) {
                        // Ensure workExperiences is always an array
                        workExperiences = Object.values(jrDocData?.work_experiences);
                    }

                    if (!Array.isArray(workExperiences)) {
                        // Ensure workExperiences is always an array
                        workExperiences = [];
                    }

                    for( const w of workExperiences ) {
                        if ( !experienceArray.includes(w) ) {
                            experienceArray.push(w);
                        }
                    }
                })

                experienceArray.forEach((item)=>{
                    if(item.from === "" || item.from === undefined){
                        item.total_experience = 0
                    } else {
                        item.total_experience = getTotalYearsYYYYMM(item.from, item.to)
                    }
                })

                candidateData['work_experience'] = experienceArray;
                candidateData['exception_status'] = getExceptionStatus(candidateData);

                candidates.push({ 
                    ...candidateData,
                    id: doc.id, 
                    no_of_events: candidateData.jr_number ? candidateData.jr_number.length : 0
                 });
            } catch (error) {
                console.error("error >", error, "candidate id >", doc.id, "candidate full name >", candidateData.full_name);
                candidates.push({ 
                    ...candidateData,
                    id: doc.id, 
                    no_of_events: candidateData.jr_number ? candidateData.jr_number.length : 0
                 });
            }
        }));
    } catch (error) {
        console.error("Error fetching candidates:", error);
    }

    return candidates;
}

const getCandidateEvents = async (eventId) => {
    // Get all document in a subcollection
    let candidateEvents = [];
    const querySnapshot = await getDocs(collection(db, "candidates", eventId,"events"));
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        candidateEvents.push({"id" : doc.id,"data" : doc.data()});
    });
    //console.log(candidateEvents);
    return candidateEvents;
}


const addEventToCandidate = async (eventId, candidateId) => {
    await setDoc(doc(db, "candidates", candidateId, 'events', eventId), {});
    //console.log("Added event: " + eventId + " to candidate: " + candidateId);
}

const deleteEventFromCandidate = async (eventId, candidateId) => {
    await deleteDoc(doc(db, "candidates", candidateId, 'events', eventId), {});
    //console.log("Deleted event: " + eventId + " from candidate: " + candidateId);
}

const updateCandidateBlacklistStatus = async (candidateId, blacklistStatus) => {
    await updateDoc(doc(db, "candidates", candidateId), { is_blacklist: blacklistStatus });
    //console.log("Candidate (" + candidateId + ") is_backlist updated: " + blacklistStatus);
}

const updateCandidateCoolingPeriod = async (candidateId, coolingPeriod) => {
    await updateDoc(doc(db, "candidates", candidateId), { cooling_period_expiry: coolingPeriod });
    //console.log("Candidate (" + candidateId + ") is_backlist updated: " + blacklistStatus);
}

const updateCandidateRegistrationStatus = async (candidateId, registrationStatus) => {
    await updateDoc(doc(db, "candidates", candidateId), { 
        exception_status: registrationStatus,
        registration_status: "Confirmed"
     });
    //console.log("Candidate (" + candidateId + ") registration_status updated: " + registrationStatus);
}

const updateCandidateAssessmentStatus = async (candidateId, assessmentStatus) => {
    await updateDoc(doc(db, "candidates", candidateId), { assessment_status: assessmentStatus });
    //console.log("Candidate (" + candidateId + ") assessment_status updated: " + assessmentStatus);
}

const triggerUpdateAssessmentStatus = async (candidateId, eventId) => {
    // Get all result to process current assessment status.
    // Trigger whenever result is saved.
    const docRef = doc(db, "events", eventId);
    const docSnap = await getDoc(docRef);
    const eventModuleCount = docSnap.data().event_assessment_module.length
    if(eventModuleCount === 0){
        // Skip count for empty assessment module
        return
    }

    const querySnapshot = await getDocs(collection(db, "candidates", candidateId, "assessment", eventId, "result"));
    const rawResult = []
    const overallResult = {}
    
    querySnapshot.forEach((doc) => {
        rawResult.push({
            module: doc.id,
            result: doc.data()
        })

        const result = doc.data()

        switch(doc.id){
            case "measurement":
                overallResult.measurement = result.overall_score.status
                break
            case "document_check":
                overallResult.document_check = result.overall_score.status
                break
            case "catwalk":
                let catwalkResult = ""
                switch(result.overall_score.toUpperCase()){
                    case "A":
                    case "B":
                    case "C":
                        catwalkResult = "Passed"
                        break
                    case "D":
                        catwalkResult = "Failed"
                        break
                    default:
                        catwalkResult = "KIV"
                        break
                }
                overallResult.catwalk = catwalkResult
                break
            case "english_exam":
                overallResult.english_exam = result.status
                break
            case "group_dynamic":
                overallResult.group_dynamic = result.status
                break
            case "one_to_one_interview":
                overallResult.one_to_one_interview = result.overall_score.status
                break
            default:
                break
        }
    })

    let assessmentStatus = ""
    const moduleCompleted = Object.keys(overallResult).length
    if(moduleCompleted === 0){
        assessmentStatus = "Pending"
    }
    else{
        let resultCount = {
            passed: 0,
            failed: 0,
            kiv: 0
        }
        
        for(const key in overallResult){
            //console.log(overallResult[key])
            switch(overallResult[key]){
                case "Passed":
                    resultCount.passed++
                    break
                case "Failed":
                    resultCount.failed++
                    break
                case "KIV":
                    resultCount.kiv++
                    break
                default:
                    break
            }
        }
        
        if(resultCount.passed === eventModuleCount){
            // All module passed
            assessmentStatus = "Passed"
        }
        else if(resultCount.failed > 0){
            // One module failed => interview failed
            assessmentStatus = "Failed"
        }
        else if(resultCount.passed > 0 && resultCount.failed === 0 && resultCount.kiv === 0){
            assessmentStatus = "In Progress"
        }
        else if(resultCount.kiv > 0){
            // One or more module is KIV, no failed => KIV
            assessmentStatus = "KIV"
        }
        else{
            assessmentStatus = "Uncaught"
        }
    }

    if(assessmentStatus !== ""){
        await updateCandidateAssessmentStatus(candidateId, assessmentStatus)
        updateDoc(doc(db, "candidates", candidateId), {
            cooling_period_expiry: "",
            is_blacklist: false,
            is_kiv: false,
        });
    }
}

const getAllJrNumberListFromCandidate = async () => {
    let jrNumberList = [];
    const querySnapshot = await getDocs(collection(db, "candidates"));
    querySnapshot.forEach((doc) => {
        if(!doc.data().jr_number){
            return
        }
        doc.data().jr_number.forEach((jrNumber) => {
            if(!jrNumberList.includes(jrNumber)){
                jrNumberList.push(jrNumber);
            }
        });
    });
    //console.log(jrNumberList);
    return jrNumberList;
}

const getJrNumberListFromCandidate = async () => {
    let jrNumberList = [];
    // const activeJrNumber = await getActiveEventJrNumber();
    const activeJrNumber = await getActiveAndCompletedEventJrNumber();
    const querySnapshot = await getDocs(collection(db, "candidates"));

    querySnapshot.forEach((doc) => {
        if(!doc.data().jr_number){
            return
        }
        doc.data().jr_number.forEach((jrNumber) => {
            if(!jrNumberList.includes(jrNumber) && !activeJrNumber.includes(jrNumber)){
                jrNumberList.push(jrNumber);
            }
        });
    });
    
    return jrNumberList;
}

const getActiveAndCompletedEventJrNumber = async () => {
    let jrNumbers = [];
    const q = query(collection(db, "events"), where("event_status", "in", ["Active", "Completed"]));
    const querySnapshot = await getDocs(q);
    
    querySnapshot.forEach((doc) => {
        if(!jrNumbers.includes(doc.data().jr_number)){
            jrNumbers.push(doc.data().jr_number);
        }
    });
    return jrNumbers;
}

const getCandidateAssessmentResultDocumentCheck = async (candidateId, eventId) => {
    const docSnap = await getDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'document_check'));
    const docData = docSnap.data();
    //console.log(docData);
    return docData;
}

const setCandidateAssessmentResultDocumentCheck = async (candidateId, eventId, documentCheckResult) => {
    //console.log(documentCheckResult)
    await setDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'document_check'), documentCheckResult)
    await triggerUpdateAssessmentStatus(candidateId, eventId)
    if("overall_score" in documentCheckResult){
        if("status" in documentCheckResult.overall_score){
            // Update result in events collection
            await updateDoc(doc(db, "events", eventId, 'candidates', candidateId), {document_check: documentCheckResult.overall_score.status})
        }
    }
}

const getCandidateAssessmentResultMeasurement = async (candidateId, eventId) => {
    const docSnap = await getDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'measurement'));
    const docData = docSnap.data();
    //console.log(docData);
    return docData;
}

const setCandidateAssessmentResultMeasurement = async (candidateId, eventId, measurementResult) => {
    //console.log(measurementResult)
    await setDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'measurement'), measurementResult)
    await triggerUpdateAssessmentStatus(candidateId, eventId)
    if("overall_score" in measurementResult){
        if("status" in measurementResult.overall_score){
            // Update result in events collection
            await updateDoc(doc(db, "events", eventId, 'candidates', candidateId), {measurement: measurementResult.overall_score.status})
        }
    }
}

const getCandidateAssessmentResultCatwalk = async (candidateId, eventId) => {
    const docSnap = await getDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'catwalk'));
    const docData = docSnap.data();
    //console.log(docData);
    return docData;
}

const setCandidateAssessmentResultCatwalk = async (candidateId, eventId, catwalkResult) => {
    //console.log(catwalkResult)
    await setDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'catwalk'), catwalkResult)
    await triggerUpdateAssessmentStatus(candidateId, eventId)
    if("overall_score" in catwalkResult){
        let result = ""
        switch(catwalkResult.overall_score.toUpperCase()){
            case "A":
            case "B":
            case "C":
                result = "Passed"
                break
            case "D":
                result = "Failed"
                break
            default:
                result = "KIV"
                break
        }
        // Update result in events collection
        await updateDoc(doc(db, "events", eventId, 'candidates', candidateId), {catwalk: result})        
        
    }
}

const getCandidateAssessmentResultOneToOneInterview = async (candidateId, eventId) => {
    const docSnap = await getDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'one_to_one_interview'));
    const docData = docSnap.data();
    //console.log(docData);
    return docData;
}

const setCandidateAssessmentResultOneToOneInterview = async (candidateId, eventId, oneToOneInterviewResult) => {
    //console.log(oneToOneInterviewResult)
    await setDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'one_to_one_interview'), oneToOneInterviewResult)
    await triggerUpdateAssessmentStatus(candidateId, eventId)
    if("overall_score" in oneToOneInterviewResult){
        if("status" in oneToOneInterviewResult.overall_score){
            // Update result in events collection
            await updateDoc(doc(db, "events", eventId, 'candidates', candidateId), {one_to_one_interview: oneToOneInterviewResult.overall_score.status})
        }
    }
}

const getCandidateAssessmentResultEnglishExam = async (candidateId, eventId) => {
    const docSnap = await getDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'english_exam'));
    const docData = docSnap.data();
    // console.log(docData);
    return docData;
}

const setCandidateAssessmentResultEnglishExam = async (candidateId, eventId, assessmentEnglishExam) => {
    //console.log(assessmentEnglishExam)
    await setDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'english_exam'), assessmentEnglishExam)
    await triggerUpdateAssessmentStatus(candidateId, eventId)
    if("status" in assessmentEnglishExam){
        // Update result in events collection
        await updateDoc(doc(db, "events", eventId, 'candidates', candidateId), {english_exam: assessmentEnglishExam.status})
    }
}

const getCandidateAssessmentResultGroupDynamic = async (candidateId, eventId) => {
    const docSnap = await getDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'group_dynamic'));
    const docData = docSnap.data();
    //console.log(docData);
    return docData;
}

const setCandidateAssessmentResultGroupDynamic = async (candidateId, eventId, assessmentGroupDynamic) => {
    //console.log(assessmentGroupDynamic)
    await setDoc(doc(db, "candidates", candidateId, 'assessment', eventId, 'result', 'group_dynamic'), assessmentGroupDynamic)
    await triggerUpdateAssessmentStatus(candidateId, eventId)
    if("status" in assessmentGroupDynamic){
        // Update result in events collection
        await updateDoc(doc(db, "events", eventId, 'candidates', candidateId), {group_dynamic: assessmentGroupDynamic.status})
    }
}

const getCandidateAssessmentResultComment = async (candidateId, eventId) => {
    const comments = []
    const querySnapshot = await getDocs(collection(db, "candidates", candidateId, 'assessment', eventId, 'comment'));
    querySnapshot.forEach((doc) => {
        comments.push({id: doc.id, ...doc.data()})
    })
    //console.log(comments)
    return comments;
}

const setCandidateAssessmentResultComment = async (candidateId, eventId, assessmentComment) => {
    //console.log(assessmentComment);
    await setDoc(doc(db, "candidates", candidateId, "assessment", eventId, "comment", assessmentComment.id.toString()), assessmentComment)
}

const getAllCandidateJobApplication = async () => {
    const jobApplications = []
    const candidates = query(collectionGroup(db, 'job_application'))
    const querySnapshot = await getDocs(candidates)
    querySnapshot.forEach((doc) => {
        //console.log(doc.id, ' => ', doc.data());
        jobApplications.push({...doc.data()})
    })
    //console.log(jobApplications)
    return jobApplications
}

const getCandidateJobApplicationById = async (candidateId) => {
    const jobApplications = []
    const candidateJobApplications = query(collection(db, "candidates", candidateId, 'job_application'))
    const querySnapshot = await getDocs(candidateJobApplications)
    querySnapshot.forEach((doc) => {
        //console.log(doc.id, ' => ', doc.data());
        jobApplications.push({...doc.data()})
    })
    //console.log(jobApplications)
    return jobApplications
}

const addJobApplicationToCandidate = async ( eventId, candidateData ) => {
    const eventDoc = await getDoc( doc(db, "events", eventId) );
    const eventData = eventDoc.data();

    const creationDate = dateToYYYYMMDD(new Date())
    
    const jobApplicationData = {
        AOC_Country: eventData.hiring_country,
        Candidate_Creation_Date: creationDate,
        Candidate_ID: candidateData.candidate_id,
        Candidate_Stage: "Review",
        City: candidateData.city,
        Company: eventData.event_aoc,
        Country: candidateData.country,
        Date_of_Birth: candidateData.dob,
        Email: candidateData.email,
        First_Name: candidateData.first_name,
        Gender: candidateData.gender,
        Job_Posting_Title: eventData.event_name,
        Job_Req_ID : eventData.jr_number,
        Job_Source: "",
        Language_Spoken: [],
        Last_Name: candidateData.last_name,
        LinkedIn_Profile: "",
        Location: eventData.event_venue,
        National_ID: candidateData.national_id,
        Nationality: candidateData.nationality,
        work_experiences: [],
    };

    await setDoc(doc(db, "candidates", candidateData.id, "job_application", eventData.jr_number), {
        ...jobApplicationData,
        updated_at: serverTimestamp()
    }, { merge: true });
}

const addCandidateJobApplication = async (candidateId, jrNumber, jobProfile) => {
    await updateDoc(doc(db, "candidates", candidateId), { 
        assessment_status: "Pending",
        jr_number: arrayUnion(jrNumber)
    })
    await setDoc(doc(db, "candidates", candidateId, "job_application", jrNumber), {
        ...jobProfile,
        updated_at: serverTimestamp()
    }, { merge: true });
}

const addCandidateBlacklistHistory = async (candidateId, blacklistDetails) => {
    let actionUser = getAuth().currentUser
    const blacklistHistory = {
        new_blacklist_value: blacklistDetails.is_blacklist,
        comment: blacklistDetails.comment,
        author: "Unknown"
    }
    const timestamp = new Date().getTime()
    if(actionUser !== null){
        blacklistHistory["author"] = actionUser.email
    }
    await setDoc(doc(db, "candidates", candidateId, "blacklist_history", timestamp.toString()), blacklistHistory)
}

const addCandidateCoolingPeriodHistory = async (candidateId, coolingPeriodDetails) => {
    let actionUser = getAuth().currentUser
    const coolingPeriodHistory = {
        new_cooling_period: coolingPeriodDetails.cooling_period,
        comment: coolingPeriodDetails.comment,
        author: "Unknown"
    }
    const timestamp = new Date().getTime()
    if(actionUser !== null){
        coolingPeriodHistory["author"] = actionUser.email
    }
    await setDoc(doc(db, "candidates", candidateId, "cooling_period_history", timestamp.toString()), coolingPeriodHistory)
}

const checkIfCandidateIsAttending = async ( candidateID, jrNumber ) => {
    const candidateDoc = await getDoc( doc(db, "candidates", candidateID, "attendance", jrNumber ) );
    const exists = candidateDoc.exists();

    return exists;
}

const getCompletedEventRemarks = async ( candidateId, eventId ) => {
    const remarkDoc = await getDoc( doc( db, "candidates", candidateId, "events_completed", eventId ) );

    if ( remarkDoc.exists() ) {
        return remarkDoc.data().remarks ?? ""
    } else {
        return "";
    }
}

const addLoginActivity = async ( logData ) => {

    const logId = new Date().toISOString();

    await setDoc(doc( db, "logs", "candidates", "login", logId ), {
        ...logData,
        created_at: serverTimestamp()
    } );
}

/**
 * Update/Override overall status of a candidate
 * @param {string} candidateId 
 * @param {string} eventId 
 * @param {string} status;
 */
const updateOverAllStatus = async ( candidateId, eventId, status ) => {
    try {
        const user = getAuth().currentUser?.email ?? null;

        await setDoc(
            doc( db, "candidates", candidateId, "assessment", eventId ), {
                overall_status : status,
                updated_at: serverTimestamp(),
                updated_by: user
            }
        )
    } catch(error) {
        console.error('updateOverAllStatus::error', error)
    }
}

/**
 * Gets over all status
 * @param {string} candidateId 
 * @param {string} eventId 
 * @returns {Promise<string>} over all status
 */
const getOverAllStatus = async ( candidateId, eventId ) => {
    try {
        const overAllStatusDoc = await getDoc(
            doc( db, "candidates", candidateId, "assessment", eventId )
        );

        if ( overAllStatusDoc.exists() ) {
            return overAllStatusDoc.data().overall_status ?? "";
        } else {
            console.error('getOverAllStatus:error does not exists');
            return "";
        }

    } catch(error) {
        console.error('getOverAllStatus:error', error);
        return "";
    }
}

/**
 * Export candidates into csv
 * @param {*} candidates 
 * @returns {string} readable as csv
 */
const convertCandidatesToCSVString = ( candidates ) => {
    let csv = objectToCSV(
        [
            { label: "First Name", key: "first_name" },
            { label: "Last Name", key: "last_name" },
            { label: "Registration Status", key: "registration_status" },
            { label: "Application Date", key: "application_date" },
            { label: "# of events", key: "no_of_events" },
            { label: "Gender", key: "gender" },
            { label: "City", key: "city" },
            { label: "Country", key: "country" },
            { label: "Source", key: "source" }
        ],
        candidates,
    );

    const fileName = `candidates-export-${new Date().toISOString()}.csv`
        
    const blob = new Blob([csv], {
        type: "text/csv;charset=utf-8;",
    });
    saveAs(blob, fileName);

    return csv;
}

const downloadEventCandidateAttachment = async ( eventId, candidateId, attachmentId ) => {
    const attachmentDoc = await getDoc( doc( db, "events", eventId, "attachment", candidateId, "details", attachmentId));

    if ( attachmentDoc.exists() ) {
        const attachmentDocData = attachmentDoc.data();
        const fileUrl = attachmentDocData.url;

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = fileUrl;

        // Extract the file name from the URL
        const fileName = fileUrl.split('/').pop();
        
        // Set the download attribute with the file name
        link.download = fileName;

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger the click event on the link
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    }
}

const getEventCandidateFileList = async ( eventId, candidateId ) => {
    try {
        const q = query( collection(db, "events", eventId, "attachment", candidateId, "details") );

        const attachmentQS = await getDocs(q);

        const hasAttachments = attachmentQS.size > 0;
        
        if ( hasAttachments ) {
            let _attachments = [];

            for( const attachmentDoc of attachmentQS.docs ) {
                const attachmentData = attachmentDoc.data();

                _attachments.push({
                    id: attachmentDoc.id,
                    description: attachmentData.description ?? "",
                    file_name: attachmentData.file_name ?? "",
                    uploaded_at: dateToYYYYMMDD(attachmentData.uploaded_at?.toDate()) ?? "",
                });
            }

            console.log('_attachments', _attachments );
            return _attachments;
        } else {
            return [];
        }
    } catch(error) {
        console.warn(error);
        return [];
    }
}

const getAllEventCandidateFileList = async (candidateId) => {
    const ids = await getCandidateEventAttendedId(candidateId)
    let eventList = []
    for (const eventId of ids){
        if(eventId.length < 1){
            continue
        }
        const res  = await getEventCandidateFileList(eventId, candidateId)
        res.forEach((event) => {
            event.eventId = eventId
        })
        eventList.push(...res)
    }
    return eventList
}

const autoSuggestOptions = async ( key, value ) => {    
    let q;

    if (key.includes("job_")) {
        q = query(
            collection(db, "configs", "filters", key ),
            orderBy("name"),
            startAfter(value),
            endBefore(value + `\uf8ff`),
            limit(10)
        );
    } else {
        q = query(
            collection(db, "candidates"),
            orderBy(key),
            startAfter(value),
            endBefore(value + '\uf8ff'),
            limit(10)
        );
    }

    const qs = await getDocs(q);

    let _options = [];
    if ( key.includes("job_") ) {
        _options = qs.docs.map((d) => d.data().name ?? "" );
    } else {
        _options = qs.docs.map((d) => d.data()[key] ?? "" );
    }
    
    let _filteredOptions = [];
    for( const o of _options ) {
        if ( o !== "" && !_filteredOptions.includes(o) ) {
            _filteredOptions.push(o);
        }
    }

    return _filteredOptions;
}

export {
    registerWalkInCandidate,
    getCandidateWalkInRegistrationStatus,
    getCandidateById, 
    getBlacklistCandidates, 
    getAllCandidate, 
    getCandidateEvents, 
    addEventToCandidate, 
    deleteEventFromCandidate, 
    updateCandidateBlacklistStatus,
    updateCandidateCoolingPeriod,
    updateCandidateRegistrationStatus,
    updateCandidateAssessmentStatus,
    triggerUpdateAssessmentStatus,
    getAllJrNumberListFromCandidate, 
    getJrNumberListFromCandidate,
    getCandidateAssessmentResultDocumentCheck,
    setCandidateAssessmentResultDocumentCheck,
    getCandidateAssessmentResultMeasurement,
    setCandidateAssessmentResultMeasurement,
    getCandidateAssessmentResultCatwalk,
    setCandidateAssessmentResultCatwalk,
    getCandidateAssessmentResultOneToOneInterview,
    setCandidateAssessmentResultOneToOneInterview,
    getCandidateAssessmentResultEnglishExam,
    setCandidateAssessmentResultEnglishExam,
    getCandidateAssessmentResultGroupDynamic,
    setCandidateAssessmentResultGroupDynamic,
    getCandidateAssessmentResultComment,
    setCandidateAssessmentResultComment,
    getAllCandidateJobApplication,
    getCandidateJobApplicationById,
    addCandidateJobApplication,
    addCandidateBlacklistHistory,
    addCandidateCoolingPeriodHistory,
    checkIfCandidateIsAttending,
    checkIfCandidateExists,
    getCompletedEventRemarks,
    addLoginActivity,
    updateOverAllStatus,
    getOverAllStatus,
    convertCandidatesToCSVString,
    addJobApplicationToCandidate,
    getCandidateList,
    checkIfNationalIdExists,
    updateRegistrationData,
    getEventCandidateFileList,
    downloadEventCandidateAttachment,
    autoSuggestOptions,
    getAddToEventCandidateList,
    getAllEventCandidateFileList
}